<!--- header --->
<div class="navbar is-light">
  <div class="navbar-brand" style='height = "800px"'>
    <a class="navbar-item" routerLink="/">
      <img src="/assets/hawksford-logo.png" style="max-height: 100%">
    </a>
  </div>

  <!--- Menu --->
  <div class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item" style="font-size: 24px" routerLink="/Bond">Bond Accrual</a>
      <a class="navbar-item" style="font-size: 24px" routerLink="/ProfitLoss">Profit&Loss</a>
      <a class="navbar-item" style="font-size: 24px" routerLink="/Shareholder">Shareholders</a>
      <a class="navbar-item" style="font-size: 24px" routerLink="/Expenses">Expenses</a>
      <a class="navbar-item" style="font-size: 24px" routerLink="/Audit">Reporting</a>
      <a class="navbar-item" style="font-size: 24px" routerLink="/Admin">Admin</a>
      <a class="navbar-item" *ngIf="currentUser.role" style="font-size: 24px" routerLink="/Exec">ExecView</a>
    </div>
    <nav class="navbar" role="navigation" aria-label="dropdown navigation" style="background-color:whitesmoke">
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link is-arrowless" style="font-size: 24px"><div id="list"><div class="bar1"></div><div class="bar2"></div><div class="bar3"></div></div></a> <!--onclick="myFunction($event)"-->
        <div class="navbar-dropdown is-right">
          <a class="navbar-item" style="font-size: 16px" routerLink="/Bond">Bond Accrual</a>
          <a class="navbar-item" style="font-size: 16px" routerLink="/ProfitLoss">Profit&Loss</a>
          <a class="navbar-item" style="font-size: 16px" routerLink="/Shareholder">Shareholders</a>
          <a class="navbar-item" style="font-size: 16px" routerLink="/Expenses">Expenses</a>
          <a class="navbar-item" style="font-size: 16px" routerLink="/Audit">Reporting</a>
          <hr class="navbar-divider">
          <a class="navbar-item" style="font-size: 16px" routerLink="/Admin">Admin</a>
          <a class="navbar-item" *ngIf="currentUser.role" style="font-size: 16px" routerLink="/Exec">ExecView</a>
        </div>
      </div>
    </nav>
    <div class="navbar-end">
      <!--- <a class="navbar-item" style="font-size: 24px;" (click)="trigger()">TriggerNAV</a> --->
      <a class="navbar-item" style="font-size: 24px;" (click)="logout()">Logout</a>
    </div>
  </div>
</div>
