import { Component, OnInit } from "@angular/core";
import Chart from 'chart.js';
import { ApiService } from 'src/app/api.service';

import { BrowserModule } from '@angular/platform-browser'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public canvas: any;
  public ctx;
  public datasets: any;
  public data: any;
  public profAlldata: any;
  public profdatasets: any;
  public profclicked: boolean = true;
  public profclicked1: boolean = false;
  public profclicked2: boolean = false;
  public pieDatasets: any;
  public pieData: any;
  public pieLabelsets: any;
  public pieLabel: any;
  public myChartData;
  public myPieChartData;
  public myLineChart;
  public profLineChart;
  public myChart;
  public shareholderHolding;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  public clicked2: boolean = false;
  public clicked3: boolean = true;
  public clicked4: boolean = false;
  public navDate: any;
  public shareholdersnum: any;
  public onelinedata: any;
  public twolinedata: any;
  CurrentTab: number = 1;
  isLoader: boolean = true;
  constructor(private service: ApiService) { }

  navData: any = [];
  perfData: any = [];
  plData: any = [];
  shData: any = [];
  pData: any = [];
  redData: any = [];
  bondData: any = [];
  perHoldingData: any = [];
  public accInt: any = 0;


  ngOnInit() {
    this.refreshNAVData();

    var gradientChartOptionsConfigurationWithTooltipBlue: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#2380f7"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#2380f7"
          }
        }]
      }
    };

    var gradientChartOptionsConfigurationWithTooltipPurple: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(225,78,202,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }]
      }
    };

    var gradientChartOptionsConfigurationWithTooltipOrange: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            userCallback: function (label, index, labels) {
              var lab = (label / 1000000).toString().concat("M");
              //console.log(lab);
              return lab;
            },
            //suggestedMin: 50,
            //suggestedMax: 110,
            padding: 20,
            fontColor: "#ff8a76"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(220,53,69,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#ff8a76"
          }
        }]
      }
    };

    //Piechart with shareholder holdings
    this.service.getDBholdings().subscribe(sdata => {
      this.isLoader = false
      this.canvas = document.getElementById("chartLineRed");
      this.ctx = this.canvas.getContext("2d");

      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);

      //gradientStroke.addColorStop(1, 'rgba(233,32,16,0.2)');
      //gradientStroke.addColorStop(0.4, 'rgba(233,32,16,0.0)');
      //gradientStroke.addColorStop(0, 'rgba(233,32,16,0)'); //red colors

      gradientStroke.addColorStop(1, 'rgba(225,78,202,0.3)');
      gradientStroke.addColorStop(0.4, 'rgba(225,78,202,0.2)');
      gradientStroke.addColorStop(0, 'rgba(225,78,202,0.1)');

      var pieData18 = [];
      var pieLabels18 = [];
      var pieData28 = [];
      var pieLabels28 = [];
      for (var item of sdata) {
        if (item.num.includes('P1')) {
          pieData18.push(item.percBpsHolding.toFixed(2));
          pieLabels18.push(item.num);
        } else {
          pieData28.push(item.percBpsHolding.toFixed(2));
          pieLabels28.push(item.num);
        }
      }
      this.pieDatasets = [
        pieData18,
        pieData28
      ];
      this.pieLabelsets = {
        pieLabels18,
        pieLabels28
      };
      this.pieData = this.pieDatasets[0];
      this.pieLabel = this.pieLabelsets.pieLabels18;
      var piedata = {
        labels: this.pieLabel, //['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        datasets: [{
          label: "Data",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: '#8E44AD',//'#ec250d',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: '#8E44AD',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#8E44AD',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: this.pieData,//[80, 100, 70, 80, 120, 80],
        }]
      };
      this.shareholdersnum = this.pieData.length;

      var pieConfig = {
        type: 'pie',
        data: piedata,
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            //bodySpacing: 1,
            xPadding: 0
            //mode: "nearest",
            //intersect: 0,
            //position: "nearest"
          },
          responsive: true
        }
      };
      this.myPieChartData = new Chart(this.ctx, pieConfig);
    });

    //this.updatePieOptions();
    var gradientChartOptionsConfigurationWithTooltipGreen: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(0,242,195,0.1)',//'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            /*suggestedMin: 50,
            suggestedMax: 125,*/
            userCallback: function (label, index, labels) {
              var lab = (label / 1000000).toString().concat("M");
              //console.log(lab);
              return lab;
            },
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            display: false,
            drawBorder: false,
            color: 'rgba(0,242,195,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }]
      }
    };

    var gradientChartOptionsConfigurationWithTooltipGreenProfit: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
          label: function (tooltipItems, data) {
            var newlabel: any;
            if (data.datasets.length > 1) {
              newlabel = data.datasets[tooltipItems.datasetIndex].label + ':$' + tooltipItems.yLabel;
            } else {
              newlabel = tooltipItems.yLabel + '%';
            }
            return newlabel;
          }
        }
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(0,242,195,0.1)',//'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            /*suggestedMin: 50,
            suggestedMax: 125,*/
            //userCallback: function (label, index, labels) {
            //  var lab = (label / 1000000).toString().concat("M");
            //  //console.log(lab);
            //  return lab;

            //},
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            display: false,
            drawBorder: false,
            color: 'rgba(0,242,195,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }]
      }
    };

    //line graph profit allocation
    this.service.getDBProfit().subscribe(pdata => {
      this.pData = pdata;
      var shLabels: any = [];
      var growthset: any = [];
      var movingset: any = [];
      var profitset: any = [];
      var eigh: any = [];
      var twen: any = [];

      for (var item of this.pData) {
        shLabels.push(item.navDate.toString().slice(2, 10));
        eigh.push(item.oneEightbps.toFixed(2));
        twen.push(item.twoEightbps.toFixed(2));
        profitset.push(item.profit.toFixed(2));
        growthset.push(item.percentage.toFixed(2));
        movingset.push(item.movement.toFixed(2));
      }
      var bps = [
        eigh,
        twen
      ];
      this.profdatasets = [
        profitset,
        growthset,
        movingset
      ];
      this.profAlldata = this.profdatasets[0];

      this.canvas = document.getElementById("profitChart");
      this.ctx = this.canvas.getContext("2d");

      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(0,242,195,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(0,242,195,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(0,242,195,0)'); //green colors

      var gradientStroke2 = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, 'rgba(102, 255, 0,0.2)');
      gradientStroke2.addColorStop(0.4, 'rgba(102, 255, 0,0.0)'); //green colors
      gradientStroke2.addColorStop(0, 'rgba(102, 255, 0,0)');

      this.onelinedata = {
        labels: shLabels,
        datasets: [{
          label: "Profit",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: '#00d6b4',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: '#00d6b4',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#00d6b4',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 0,
          data: this.profAlldata,
        }]
      };
      this.twolinedata = {
        labels: shLabels,
        datasets: [{
          label: "18bps",
          fill: true,
          backgroundColor: gradientStroke2,
          borderColor: '#66FF00',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: '#66FF00',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#66FF00',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 0,
          data: eigh
        }, {
          label: "28bps",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: '#00d6b4',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: '#00d6b4',
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: '#00d6b4',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 0,
          data: twen
        }
        ]
      };

      this.profLineChart = new Chart(this.ctx, {
        type: 'line',
        data: this.twolinedata,
        options: gradientChartOptionsConfigurationWithTooltipGreenProfit
      });

    });

    var shareholdersLineOptions: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(245,127,23,0.1)',//'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            /*suggestedMin: 50,
            suggestedMax: 125,*/
            userCallback: function (label, index, labels) {
              var lab = (label / 1000000).toString().concat("M");
              //console.log(lab);
              return lab;
            },
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            display: false,
            drawBorder: false,
            color: 'rgba(0,242,195,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }]
      }
    };

    //line graph with sum of shareholders
    this.service.getDBsh('all').subscribe(sdata => {
      this.shData = sdata;
      var shLabels: any = [];
      var shDataset: any = [];

      for (var item of this.shData) {
        shLabels.push(item.navDate.toString().slice(2, 10));
        shDataset.push(item.navTotal.toFixed(2));
      }
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(245,127,23,0.4)');
      gradientStroke.addColorStop(0.4, 'rgba(245,127,23,0.1)'); //orange colors
      gradientStroke.addColorStop(0, 'rgba(245,127,23,0.02)'); //orange colors
      var linedata = {
        labels: shLabels,
        datasets: [{
          label: "NAV",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: '#F57F17',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: '#F57F17',
          pointBorderColor: 'rgba(245,127,23,0)',
          pointHoverBackgroundColor: '#F57F17',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 0,
          data: shDataset,
        }]
      };

      this.canvas = document.getElementById("chartLineGreen");
      this.ctx = this.canvas.getContext("2d");




      this.myLineChart = new Chart(this.ctx, {
        type: 'line',
        data: linedata,
        options: shareholdersLineOptions

      });

    });

    // OLD MUTUAL US DOLLAR MONEY MARKET FUND
    this.performance();

    // Best Active Performing Bonds
    this.performanceInBarChart();

    // Shareholder Per Holding 
    this.shareholderPerHolding();
  }

  // OLD MUTUAL US DOLLAR MONEY MARKET FUND
  performance() {

    var gradientChartOptionsConfigurationWithTooltipRed: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
          label: function (tooltipItems, data) {
            var newlabel = 'Accrued Interest:$' + tooltipItems.yLabel;
            return newlabel;
          }
        }
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            display: true,
            drawBorder: false,
            color: 'rgba(233,32,16,0.1)',//'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            //suggestedMin: 9000,
            //suggestedMax: 9200,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            display: false,
            drawBorder: false,
            color: 'rgba(233,32,16,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }]
      }
    };

    this.service.getDBbonds(this.CurrentTab).subscribe(ndata => {
      this.navData = ndata;
      var sumMat = 0;
      var sumYTD = 0;
      var chart_labels: any = [];
      var accPD: any = [];
      var accM: any = [];
      var accYTD: any = [];


      for (var item of this.navData) {
        this.accInt = this.accInt + item.accIntPerDay;
        sumMat = sumMat + item.accIntMatured;
        sumYTD = sumYTD + item.accYTD;

        chart_labels.push(item.navDate.toString().slice(2, 10));
        accPD.push(item.accIntPerDay.toFixed(2));
        accM.push(item.accIntMatured.toFixed(2));
        accYTD.push(item.accYTD.toFixed(2));
      }

      //var chart_labels = ['MAY \'19', 'JUN \'19', 'JUL \'19', 'AUG \'19', 'SEP \'19', 'OCT \'19', 'NOV \'19', 'DEC \'19', 'JAN \'20', 'FEB \'20', 'MAR \'20', 'APR \'20'];
      //this.datasets = [
      //  [this.accInt + 75, this.accInt + 60, this.accInt - 90, this.accInt, this.accInt + 110, this.accInt, this.accInt + 70, this.accInt + 90, this.accInt + 70, this.accInt + 85, this.accInt - 10, this.accInt],
      //  [sumMat + 55, sumMat + 80, sumMat - 90, sumMat, sumMat + 10, sumMat, sumMat + 30, sumMat + 100, sumMat - 20, sumMat - 85, sumMat - 10, sumMat],
      //  [sumYTD + 75, sumYTD + 60, sumYTD - 90, sumYTD, sumYTD + 110, sumYTD, sumYTD + 70, sumYTD + 90, sumYTD + 70, sumYTD + 85, sumYTD - 10, sumYTD]
      //];
      this.datasets = [
        accPD,
        accM,
        accYTD
      ];
      this.data = this.datasets[0];

      this.canvas = document.getElementById("chartBig1");
      this.ctx = this.canvas.getContext("2d");

      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(233,32,16,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(233,32,16,0.0)');
      gradientStroke.addColorStop(0, 'rgba(233,32,16,0)'); //red colors

      if (this.myChartData) {
        this.myChartData.destroy();
      }

      var config = {        //this is the main big chart
        type: 'line',
        data: {
          labels: chart_labels,
          datasets: [{
            label: "Accrued Interest",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: '#ec250d',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#ec250d',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#ec250d',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 0,
            data: this.data,
          }]
        },
        options: gradientChartOptionsConfigurationWithTooltipRed
      };
      this.myChartData = new Chart(this.ctx, config);

    });
  }
  // Best Active Performing Bonds
  performanceInBarChart() {

    var gradientBarChartConfiguration: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
          label: function (tooltipItems, data) {
            var newlabel = 'Accrued Interest YTD:$' + tooltipItems.yLabel;
            return newlabel;
          }
        }
      },
      responsive: true,
      scales: {
        yAxes: [{

          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            /*suggestedMin: 60,
            suggestedMax: 120,*/
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }],

        xAxes: [{

          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }]
      }
    };

    //bar graph of best performing bonds
    this.service.getPerfBonds(this.CurrentTab).subscribe(pdata => {
      this.perfData = pdata;
      var chart_labels: any = [];
      var accInt: any = [];
      for (var item of this.perfData) {
        //console.log(item);
        chart_labels.push(item.fundID);
        accInt.push(item.accYTD);
      }
      this.navDate = this.perfData[0].navDate.toString().slice(0, 10);


      this.canvas = document.getElementById("CountryChart");
      this.ctx = this.canvas.getContext("2d");
      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.3)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0.1)'); //blue colors

      if (this.myChart) {
        this.myChart.destroy();
      }
      this.myChart = new Chart(this.ctx, {
        type: 'bar',
        responsive: true,
        legend: {
          display: false
        },
        data: {
          labels: chart_labels,//['FDNED291119AQ1, 301120', 'FDRMB090919AQ1, 090620', 'FDRMB140519AQ1, 140520', 'FDRMB300819AQ1, 310820', 'FDNED140519AQ3, 151121', 'FDRMB010819AQ1, 010720'],
          datasets: [{
            label: "Accrued Interest YTD",
            fill: true,
            backgroundColor: gradientStroke,
            hoverBackgroundColor: gradientStroke,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: accInt,//[41622.78, 77847.26, 258131.25, 96638.89, 45048.6, 49102.66],
          }]
        },
        options: gradientBarChartConfiguration
      });

    });
  }

  // Shareholder Per Holding 
  shareholderPerHolding() {
    this.service.ShareholderPerHolding(this.CurrentTab).subscribe((sdata: any) => {
      this.isLoader = false
      this.canvas = document.getElementById("perHolding");
      this.ctx = this.canvas.getContext("2d");
      var gradientStroke = this.ctx?.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, 'rgba(29,140,248,0.3)');
      gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.2)');
      gradientStroke.addColorStop(0, 'rgba(29,140,248,0.1)'); //blue colors

      if (this.shareholderHolding) {
        this.shareholderHolding.destroy();
      }

      this.shareholderHolding = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: sdata.data.label,
          datasets: [{
            label: '% Holdings',
            data: sdata.data.data,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          }]
        }, options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel + '%';
              }
            }
          }
        }
      });
    });
  }

  refreshNAVData() {
    this.service.getExpBonds(this.CurrentTab).subscribe(data => {
      this.isLoader = false
      this.plData = data;
    });
    this.service.getRedsFilter(5, this.CurrentTab).subscribe(rdata => {
      this.isLoader = false
      this.redData = rdata;
    });
    this.service.getBondUpdatesFilter(5, this.CurrentTab).subscribe(bdata => {
      this.isLoader = false
      this.bondData = bdata;
    });
  }

  public updateOptions() {
    this.myChartData.data.datasets[0].data = this.data;
    this.myChartData.update();
    this.myPieChartData.data.datasets[0].data = this.pieData;
    this.myPieChartData.data.labels = this.pieLabel;
    this.shareholdersnum = this.pieData.length;
    this.myPieChartData.update();
  }

  public updateProfOpt() {
    if (this.profclicked) {
      this.profLineChart.data = this.twolinedata;
      this.profLineChart.update();
      document.getElementById('profusd').style.backgroundColor = '#26A69A';
      document.getElementById('profperc').style.backgroundColor = '#00d6b4';
      document.getElementById('profmov').style.backgroundColor = '#00d6b4';
    } else {
      this.profLineChart.data = this.onelinedata;
      this.profLineChart.data.datasets[0].data = this.profAlldata;
      this.profLineChart.update();
      if (this.profclicked1) {
        document.getElementById('profusd').style.backgroundColor = '#00d6b4';
        document.getElementById('profperc').style.backgroundColor = '#26A69A';
        document.getElementById('profmov').style.backgroundColor = '#00d6b4';
      } else {
        document.getElementById('profusd').style.backgroundColor = '#00d6b4';
        document.getElementById('profperc').style.backgroundColor = '#00d6b4';
        document.getElementById('profmov').style.backgroundColor = '#26A69A';
      }
    }
  }
  public pieColours() {
    if (this.clicked3) {
      document.getElementById('pie18').style.backgroundColor = '#8E44AD';
      document.getElementById('pie28').style.backgroundColor = '#E1BEE7';
    } else {
      document.getElementById('pie18').style.backgroundColor = '#E1BEE7';
      document.getElementById('pie28').style.backgroundColor = '#8E44AD';
    }
  }

  public updatePieOptions() {
    this.myPieChartData.data.datasets[0].data = this.pieData;
    this.myPieChartData.data.labels = this.pieLabel;
    this.shareholdersnum = this.pieData.length;
    this.myPieChartData.update();
  }

  activeBtn(value: number) {
    this.CurrentTab = value;
    this.isLoader = true
    this.performance();
    this.refreshNAVData();
    this.performanceInBarChart();
    this.shareholderPerHolding();
  }
}
