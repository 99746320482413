<div class="tab">
  <ul class="main_tab">
    <li (click)="activeBtn(1)" [class]="CurrentTab === 1 ? 'active' : ''">Old Mutual US Dollar Money Market Fund</li>
    <li (click)="activeBtn(2)" [class]="CurrentTab === 2 ? 'active' : ''">Old Mutual US Dollar Duration Fund</li>
  </ul>
</div>
<div class="content">
  <div class="row">
    <div class="column" style="float:left; width:33.33%; padding-left:50px">
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr class="is-selected">
            <th></th>
            <th>Expense</th>
            <th>Per Annum</th>
            <!--<th>No. of Days</th>-->
            <th>Days Left</th>
            <th>Per Day</th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th id="sumExp"></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of expData; let i = index">
            <th>{{i + 1}}</th>
            <td>{{dataItem.description}}</td>
            <td>{{dataItem.expense.toFixed(2)}}</td>
            <!--<td>{{dataItem.fundID}}</td>-->
            <td>{{dataItem.daysLeft}}</td>
            <td>{{dataItem.expPerDay.toFixed(2)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column" style="float:left; width:33.33%;padding-left:50px">
      <!--<div style="width: 500px; padding-top: 30px; ">
      <label class="label">Please insert additional expense below.</label>
      <div class="field ">
        <div class="control">
          <input class="input" type="text" placeholder="Expense Description">
        </div>
      </div>
      <div>

      </div>
      <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">
        <div class="control">
          <input class="input" type="text" placeholder="Currency">
        </div>
        <div class="control">
          <input class="input" type="text" placeholder="Amount" style="width:295px">
        </div>
      </div>
      <div class="field ">
        <div class="control">
          <input class="input" type="text" placeholder="Days">
        </div>
      </div>

      <div class="buttons is-right are-small" style="padding-top: 10px">
        <button class="button is-success">Add Expense</button>
      </div>
    </div>
  </div>-->
      <form [formGroup]="categoryForm" (ngSubmit)="onSubmitCategory()" class="form-inline">
        <div style="width: 100%; padding-top: 30px; padding-bottom: 30px; padding-left: 50px;">
          <label class="label">Add New Category</label>
          <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">

            <div class="form-group">
              <input formControlName="name" class="form-control" type="text" placeholder="Name of Category"
                [ngClass]="{ 'is-invalid': submittedCategory && categoryForm.controls['name'].errors }" maxlength="100">

              <div *ngIf="submittedCategory && categoryForm.controls['name'].errors" class="invalid-feedback">
                <div *ngIf="categoryForm.controls['name'].errors">Name is required</div>
              </div>
            </div>
            <div class="form-group pl-2">
              <textarea formControlName="description" class="form-control" placeholder="Description"
                maxlength="50"></textarea>
            </div>

            <button class="btn btn-success" [disabled]="btnCategoryLoader">
              {{ btnCategoryLoader ? "Loading" : "Add Category" }} 
            </button>

          </div>
        </div>
      </form>

      <form [formGroup]="newExpenseForm" (ngSubmit)="onNewSubmit()" class="form-inline">
        <div style="width: 100%; padding-top: 30px; padding-bottom: 30px; padding-left: 50px;">
          <label class="label">Add New Expense</label>
          <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">

            <select class="form-control" formControlName="expense"
              [ngClass]="{ 'is-invalid': submittednew && e.expense.errors }" [(ngModel)]="categoryvalue">
              <option [ngValue]="undefined" disabled>-- Select Category --</option>
              <option *ngFor="let description of Category">{{description.name}}</option>
            </select>
            <div *ngIf="submittednew && e.expense.errors" class="invalid-feedback">
              <div *ngIf="e.expense.errors.required">Category is required</div>
            </div>

            <div class="form-group pl-2">
              <input formControlName="date" class="form-control" type="date" placeholder="Start Date"
                [ngClass]="{ 'is-invalid': submittednew && e.date.errors }">
              <div *ngIf="submittednew && e.date.errors" class="invalid-feedback">
                <div *ngIf="e.date.errors.required">Date is required</div>
              </div>
            </div>
            <div class="form-group pl-2">
              <input formControlName="amount" class="form-control" type="number" step="0.01" placeholder="Enter Amount"
                [ngClass]="{ 'is-invalid': submittednew && e.amount.errors }"
                onKeyPress="if(this.value.length==8) return false;">
              <div *ngIf="submittednew && e.amount.errors" class="invalid-feedback">
                <div *ngIf="e.amount.errors.required">Ammount is Required</div>
              </div>
            </div>
            <div class="form-group pl-2">
              <input formControlName="days" class="form-control" type="number" placeholder="Number of Days"
                [ngClass]="{ 'is-invalid': submittednew && e.days.errors }"
                onKeyPress="if(this.value.length==3) return false;">
              <div *ngIf="submittednew && e.days.errors" class="invalid-feedback">
                <div *ngIf="e.days.errors.required">Number of Days Required</div>
              </div>
            </div>
            <!--<div class="form-group pl-2 pr-2">
              <style>
                .tooltiptext {
                  visibility: hidden;
                  width: 120px;
                  background-color: #00d1b2;
                  color: #fff;
                  text-align: center;
                  border-radius: 6px;
                  padding: 5px 0;
                  /* Position the tooltip */
                  position: absolute;
                  z-index: 1;
                  margin-left: 60px;
                }
                .pr-2:hover .tooltiptext {
                  visibility: visible;
                }
              </style>
              <input type="checkbox" style="background-color:#28a745" formControlName="perAnum" (click)="changePA();">p.a.
              <span class="tooltiptext">Expense to be paid out per day over a year (i.e. ex</span>
            </div>-->

            <button class="btn btn-success" [disabled]="btnAddExpenseLoader">
              {{ btnAddExpenseLoader ? "Loading" : "Add Expense" }} 
            </button>

          </div>
        </div>
      </form>

      <form [formGroup]="ExpenseForm" (ngSubmit)="onSubmit()" class="form-inline">
        <div style="width: 100%; padding-top: 30px; padding-bottom: 30px; padding-left: 50px;">
          <label class="label">Edit Expense</label>
          <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">

            <div class="form-group">
              <select class="form-control" formControlName="expenseDescriptions"
                [ngClass]="{ 'is-invalid': submitted && f.expenseDescriptions.errors }" [(ngModel)]="expensevalue">
                <option [ngValue]="undefined">Please select an expense</option>
                <option *ngFor="let description of editDescriptions">{{description}}</option>
              </select>
              <div *ngIf="submitted && f.expenseDescriptions.errors" class="invalid-feedback">
                <div *ngIf="f.expenseDescriptions.errors.required">Account Number is required</div>
              </div>
            </div>
            <div class="form-group pl-2">
              <input formControlName="date" class="form-control" type="date" placeholder="Date"
                [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
              <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                <div *ngIf="f.date.errors.required">Date is required</div>
              </div>
            </div>
            <div class="form-group pl-2">
              <input formControlName="amount" class="form-control" type="number" step="0.01" placeholder="Enter Amount"
                [ngClass]="{ 'is-invalid': submitted && f.amount.errors }"
                onKeyPress="if(this.value.length==8) return false;">
              <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                <div *ngIf="f.amount.errors.required">Ammount is Required</div>
              </div>
            </div>
            <div class="form-group pl-2 pr-2">
              <input formControlName="days" class="form-control" type="number" placeholder="Number of Days"
                [ngClass]="{ 'is-invalid': submitted && f.days.errors }"
                onKeyPress="if(this.value.length==3) return false;">
              <div *ngIf="submitted && f.days.errors" class="invalid-feedback">
                <div *ngIf="f.days.errors.required">Number of Days Required</div>
              </div>
            </div>

            <button class="btn btn-success" [disabled]="btnEditExpenseLoader">
              {{ btnEditExpenseLoader ? "Loading" : "Edit Expense" }} 
            </button>

          </div>
        </div>
      </form>

    </div>
    <div class="column" style="float:left; width:33.33%">
      <canvas id="pieChart"></canvas>
    </div>
  </div>
  <div class="row">
    <div class="column" style="float:left; width:33.33%; padding-left:50px">
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr class="is-selected">
            <th></th>
            <th>Class C Expense</th>
            <th>Per Annum</th>
            <th>Days Left</th>
            <!--<th>No. of Days</th>-->
            <th>Per Day</th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th id="sumCCExp"></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of expCCData; let i = index">
            <th>{{i + 1}}</th>
            <td>{{dataItem.description}}</td>
            <td>{{dataItem.expense.toFixed(2)}}</td>
            <!--<td>{{dataItem.fundID}}</td>-->
            <td>{{dataItem.daysLeft}}</td>
            <td>{{dataItem.expPerDay.toFixed(2)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column" style="float:left; width:33.33%;padding-left:50px">

      <form [formGroup]="ExpenseCCForm" (ngSubmit)="addNewCC()" class="form-inline">
        <div style="width: 100%; padding-top: 30px; padding-bottom: 30px; padding-left: 50px;">
          <label class="label">Add Class C Expense</label>
          <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">

            <!-- <div class="form-group">
              <input formControlName="description" class="form-control" type="text" placeholder="Name of Expense"
                [ngClass]="{ 'is-invalid': submittedNewCC && ExpenseCCForm.controls['description'].errors }"
                maxlength="20">
              <div *ngIf="submittedNewCC && ExpenseCCForm.controls['description'].errors" class="invalid-feedback">
                <div *ngIf="ExpenseCCForm.controls['description'].errors">Name is required</div>
              </div>
            </div> -->

            <select class="form-control" formControlName="description"
              [ngClass]="{ 'is-invalid': submittedNewCC && ExpenseCCForm.controls['description'].errors }" 
              [(ngModel)]="categoryvalue">
              <option [ngValue]="undefined" disabled>-- Select Category --</option>
              <option *ngFor="let description of Category">{{description.name}}</option>
            </select>
            <div *ngIf="submittednew && e.expense.errors" class="invalid-feedback">
              <div *ngIf="e.expense.errors.required">Category is required</div>
            </div>

            <div class="form-group pl-2">
              <input formControlName="date" class="form-control" type="date" placeholder="Date"
                [ngClass]="{ 'is-invalid': submittedNewCC && ExpenseCCForm.controls['date'].errors }">
              <div *ngIf="submittedNewCC && ExpenseCCForm.controls['date'].errors" class="invalid-feedback">
                <div *ngIf="ExpenseCCForm.controls['date'].errors">Date is required</div>
              </div>
            </div>
            <div class="form-group pl-2">
              <input formControlName="amount" class="form-control" type="number" step="0.01" placeholder="Enter Amount"
                [ngClass]="{ 'is-invalid': submittedNewCC && ExpenseCCForm.controls['amount'].errors }"
                onKeyPress="if(this.value.length==8) return false;">
              <div *ngIf="submittedNewCC && ExpenseCCForm.controls['amount'].errors" class="invalid-feedback">
                <div *ngIf="ExpenseCCForm.controls['amount'].errors">Ammount is Required</div>
              </div>
            </div>
            <div class="form-group pl-2 pr-2">
              <input formControlName="days" class="form-control" type="number" placeholder="Number of Days"
                [ngClass]="{ 'is-invalid': submittedNewCC && ExpenseCCForm.controls['days'].errors }"
                onKeyPress="if(this.value.length==3) return false;">
              <div *ngIf="submittedNewCC && ExpenseCCForm.controls['days'].errors" class="invalid-feedback">
                <div *ngIf="ExpenseCCForm.controls['days'].errors">Number of Days Required</div>
              </div>
            </div>

            <button class="btn btn-success" [disabled]="btnAddExpenseCCLoader">
              {{ btnAddExpenseCCLoader ? "Loading" : "Add CC Expense" }} 
            </button>

          </div>
        </div>
      </form>


      <form [formGroup]="UpdateExpenseCCForm" (ngSubmit)="updateCC()" class="form-inline">
        <div style="width: 100%; padding-top: 30px; padding-bottom: 30px; padding-left: 50px;">
          <label class="label">Edit Class C Expense</label>
          <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">

            <div class="form-group">
              <select class="form-control" formControlName="id"
                [ngClass]="{ 'is-invalid': submittedcc  && UpdateExpenseCCForm.controls['id'].value == 0}">
                <option value="0">Please select an expense</option>
                <option *ngFor="let item of expCCData" [value]="item.id">{{item.description}}</option>
              </select>
              <div *ngIf="submittedcc && UpdateExpenseCCForm.controls['id'].value == 0" class="invalid-feedback">
                <div *ngIf="submittedcc && UpdateExpenseCCForm.controls['id'].value == 0">Account Number is required
                </div>
              </div>
            </div>
            <div class="form-group pl-2">
              <input formControlName="date" class="form-control" type="date" placeholder="Date"
                [ngClass]="{ 'is-invalid': submittedcc && UpdateExpenseCCForm.controls['date'].errors }">
              <div *ngIf="submittedcc && UpdateExpenseCCForm.controls['date'].errors" class="invalid-feedback">
                <div *ngIf="UpdateExpenseCCForm.controls['date'].errors">Date is required</div>
              </div>
            </div>
            <div class="form-group pl-2">
              <input formControlName="amount" class="form-control" type="number" step="0.01" placeholder="Enter Amount"
                [ngClass]="{ 'is-invalid': submittedcc && UpdateExpenseCCForm.controls['amount'].errors }"
                onKeyPress="if(this.value.length==8) return false;">
              <div *ngIf="submittedcc && UpdateExpenseCCForm.controls['amount'].errors" class="invalid-feedback">
                <div *ngIf="UpdateExpenseCCForm.controls['amount'].errors">Ammount is Required</div>
              </div>
            </div>
            <div class="form-group pl-2 pr-2">
              <input formControlName="days" class="form-control" type="number" placeholder="Number of Days"
                [ngClass]="{ 'is-invalid': submittedcc && UpdateExpenseCCForm.controls['days'].errors }"
                onKeyPress="if(this.value.length==3) return false;">
              <div *ngIf="submittedcc && UpdateExpenseCCForm.controls['days'].errors" class="invalid-feedback">
                <div *ngIf="UpdateExpenseCCForm.controls['days'].errors">Number of Days Required</div>
              </div>
            </div>

            <button class="btn btn-success" [disabled]="btnEditExpenseCCLoader">
              {{ btnEditExpenseCCLoader ? "Loading" : "Edit CC Expense" }} 
            </button>

          </div>
        </div>
      </form>
    </div>
    <div class="column" style="float:left; width:33.33%">
      <canvas id="CCpieChart"></canvas>
    </div>

  </div>

</div>
<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>