import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NavdataComponent } from './navdata/navdata.component';
import { ShowDataComponent } from './navdata/show-data/show-data.component';
import { BondAcruelComponent } from './bond-acruel/bond-acruel.component';
import { ProfitLossComponent } from './Profit-loss/profit-loss.component';
import { ShareholderComponent } from './Shareholder/shareholder.component';
import { HomeComponent } from './home/home.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { AuditComponent } from 'src/app/audit/audit.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { ExecComponent } from './exec/exec.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent },

      {
        path: 'navdata',
        component: NavdataComponent
      },
      {
        path: 'Bond',
        component: BondAcruelComponent
      },
      {
        path: 'ProfitLoss',
        component: ProfitLossComponent
      },
      {
        path: 'Shareholder',
        component: ShareholderComponent
      },
      {
        path: 'Expenses',
        component: ExpensesComponent
      },
      {
        path: 'Audit',
        component: AuditComponent
      },
      {
        path: 'Admin',
        component: AdminComponent
      },
      {
        path: 'Exec',
        component: ExecComponent
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
