import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import Chart from 'chart.js';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

var YTDateStart = formatDate(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd', 'en');
var YTDateCur = formatDate(new Date(), 'yyyy-MM-dd', 'en');

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['/node_modules/bulma/css/bulma.css', './audit.component.css']
})
export class AuditComponent implements OnInit {
  AuditForm: FormGroup;
  submitted = false;
  auditSubmitted = false;
  loading: boolean;
  uploadForm: FormGroup;
  CurrentTab: number = 1;
  audData: any = [];
  rows: any = [];
  classC: any = [];
  part: any = [];
  date: any;
  excel = [];
  shareholderData: any = [];
  report: number;
  filevalid = false;
  selectedFile: File;
  shareholderId: number;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
  isSubmit = false
  isLoader: boolean = true;
  btnDownloadSeriesLoader: boolean = false;
  btnDownloadYTDLoader: boolean = false;
  btnBondLoader: boolean = false;
  btnShareholderLoader: boolean = false;
  btnBalanceSheetLoader: boolean = false;

  constructor(private service: ApiService, private formBuilder: FormBuilder) { }

  onFileSelected(event): void {
    const allowedExtensions = ['xlsx', 'xls', 'pdf', 'docx', 'docm', 'dotx', 'dot'];
    const fileExtension = event.target.files[0].name.split('.').pop();

    if (!allowedExtensions.includes(fileExtension)) {
      this.filevalid = true
      this.selectedFile = null;
      return;
    }
    this.filevalid = false
    this.selectedFile = event.target.files[0] as File;
  }

  ngOnInit(): void {
    this.refreshAudData();

    this.service.getShareHolder().subscribe((data: any) => {
      this.shareholderData = data.data
    });

    //form
    this.AuditForm = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required]
    });

    this.uploadForm = this.formBuilder.group({
      shareholderId: [null, Validators.required],
      report: [null, Validators.required],
      file: [null, Validators.required]
    });
  }

  refreshAudData() {
    this.service.getAudit(this.CurrentTab).subscribe(aData => {
      this.isLoader = false
      this.audData = aData;
      for (var item of this.audData) {
        if (item.description.includes('Class')) {
          this.classC = item;
        } else {
          this.part = item;
        }
      }
      this.rows = Object.keys(this.classC);
      this.date = this.classC['navDate'].slice(0, -9);

      /*for (var row of this.part) {
        this.rows.push(row.field);
      }*/

      /*      var sumExp = 0;
            var graphData = [];
            var graphLabels = [];
            var graphColours = [];
            var color = ["Blue ", "Pink", "Red", "DarkOrange", "Violet", "Indigo", "Yellow", "Lime", "Aqua"];
      
            for (var item of this.audData) {
              sumExp = sumExp + item.expPerDay;
              if (item.description.includes('Management') || item.description.includes('Admin') || item.description.includes('Organisation')) {
                var str = new String(' (latest)');
                item.description = item.description.concat(' (latest)');
                graphLabels.push(item.description.concat(str.toString()));
              } else {
                graphLabels.push(item.description);
              }
              graphData.push(item.expPerDay);
              graphColours.push(this.getRandomColor());
            }
            document.getElementById("sumExp").innerHTML = sumExp.toFixed(2).toString();
      
            var ctx = document.getElementById("pieChart");
            var myDoughnutChart = new Chart(ctx, {
              type: 'doughnut',
              data: {
                labels: graphLabels,
                datasets: [{
                  backgroundColor: color,//graphColours,
                  data: graphData
                }]
              },
              options: {
                legend: {
                  display: false
                },
                tooltips: {
                  backgroundColor: '#f5f5f5',
                  titleFontColor: '#333',
                  bodyFontColor: '#666',
                  bodySpacing: 4,
                  xPadding: 12,
                  mode: "nearest",
                  intersect: 0,
                  position: "nearest"
                },
                responsive: true,
                maintainAspectRatio: true
              }
            });*/

    });
  }

  get audit() { return this.AuditForm.controls; }


  onAuditSubmit() {
    if (this.AuditForm.invalid) {
      return;
    }

    this.loading = true;

    const sdate = { 'fromDate': new Date(this.audit.fromDate.value).toISOString(), 'toDate': new Date(this.audit.toDate.value).toISOString() };
    /*this.service.downloadBSheet(date).subscribe(
      response => {
        const blob = new Blob([response.blob()], { type: 'application/octet-stream' });
        const file = new File([blob], this.date.replace(/-/gi, '') + '_P&L by series.xlsx', { type: 'application/octet-stream' });
        saveAs(file);
      }
    )*/

  }

  getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  downloadYTD() {
    console.log(YTDateStart, new Date(YTDateStart).toISOString().substring(0, 10), YTDateCur);

    const YTDate = { 'fromDate': YTDateStart, 'toDate': YTDateCur };

    this.btnDownloadYTDLoader = true;
    this.service.downloadPandLReport(YTDate, this.CurrentTab).subscribe(
      response => {
        this.btnDownloadYTDLoader = false;
        const blob = new Blob([response.blob()], { type: 'application/octet-stream' });
        const file = new File([blob], YTDate.toDate + '_P&L YTD.xlsx', { type: 'application/octet-stream' });
        saveAs(file);
      }
    )
  };

  downloadBond() {

    if (!(this.audit.fromDate.value && this.audit.toDate.value)) {
      window.alert("Please Select Valid Start Date And End Date");
      return;
    }

    const BondDate = { 'fromDate': new Date(this.audit.fromDate.value).toISOString().substring(0, 10), 'toDate': new Date(this.audit.toDate.value).toISOString().substring(0, 10) };

    this.btnBondLoader = true;

    this.service.downloadBondReport(BondDate.toDate, this.CurrentTab).subscribe(
      response => {
        this.btnBondLoader = false;
        const blob = new Blob([response.blob()], { type: 'application/octet-stream' });
        const file = new File([blob], BondDate.toDate.replace(/-/gi, '') + '_Bond Accruals.xlsx', { type: 'application/octet-stream' });
        saveAs(file);
      }
    )
  };

  downloadShareholders() {
    // const shDate = { 'fromDate': new Date(this.audit.fromDate.value).toISOString().substring(0, 10), 'toDate': new Date(this.audit.toDate.value).toISOString().substring(0, 10) };
    this.btnShareholderLoader = true;

    this.service.downloadShareholders(YTDateCur, this.CurrentTab).subscribe(
      response => {
        this.btnShareholderLoader = false;
        const blob = new Blob([response.blob()], { type: 'application/octet-stream' });
        const file = new File([blob], YTDateCur.replace(/-/gi, '') + '_Shareholding for shareholders.xlsx', { type: 'application/octet-stream' });
        saveAs(file);
      }
    )
  };

  download() {

    if (!(this.audit.fromDate.value && this.audit.toDate.value)) {
      window.alert("Please Select Valid Start Date And End Date");
      return;
    }

    const ydate = { 'fromDate': new Date(this.audit.fromDate.value).toISOString().substring(0, 10), 'toDate': new Date(this.audit.toDate.value).toISOString().substring(0, 10) };

    this.btnDownloadSeriesLoader = true;

    this.service.downloadPandLReport(ydate, this.CurrentTab).subscribe(
      response => {
        this.btnDownloadSeriesLoader = false;
        const blob = new Blob([response.blob()], { type: 'application/octet-stream' });
        const file = new File([blob], ydate.toDate.replace(/-/gi, '') + '_P&L by series.xlsx', { type: 'application/octet-stream' });
        saveAs(file);
      }
    )
  };

  downloadBalanceSheet() {

    if (!(this.audit.fromDate.value && this.audit.toDate.value)) {
      window.alert("Please Select Valid Start Date And End Date");
      return;
    }
    
    const shDate = { 'fromDate': new Date(this.audit.fromDate.value).toISOString().substring(0, 10), 'toDate': new Date(this.audit.toDate.value).toISOString().substring(0, 10) };
    this.btnBalanceSheetLoader = true;
    this.service.downloadBalanceSheet(shDate.toDate, this.CurrentTab).subscribe(
      response => {
        this.btnBalanceSheetLoader = false;
        const blob = new Blob([response.blob()], { type: 'application/octet-stream' });
        const file = new File([blob], shDate.toDate.replace(/-/gi, '') + '_Balance Sheet by series.xlsx', { type: 'application/octet-stream' });
        saveAs(file);
      }
    )
  };

  onUpload(): void {
    this.isSubmit = true
    if (this.uploadForm.value.report !== 1) {
      this.service.uploadFile(
        this.uploadForm.value.shareholderId,
        this.uploadForm.value.report,
        this.CurrentTab,
        this.selectedFile).subscribe((data) => {
          if (data.isSuccessful) {
            this.isSubmit = false
            window.alert('File uploaded successfully');
            this.shareholderId = undefined;
            this.report = undefined;
            this.filevalid = false;
            this.selectedFile = null;
            this.myInputVariable.nativeElement.value = "";
          }
        }, error => {
          console.log(error)
          this.isSubmit = false
          window.alert(error);
        });
    } else {
      let body = {
        userId: this.uploadForm.value.shareholderId,
        fundType: this.CurrentTab
      }
      this.service.GenerateValueGainReport(body).subscribe((data) => {
        if (data.isSuccessful) {
          this.isSubmit = false
          window.alert('Valuation report successfully generate.');
          this.shareholderId = undefined;
          this.report = undefined;
          this.myInputVariable.nativeElement.value = "";
        }
      }, error => {
        console.log(error)
        this.isSubmit = false
        window.alert(error);
      });
    }
  }

  activeBtn(value: number) {
    this.CurrentTab = value;
    this.isLoader = true
    this.refreshAudData();
  }
}
