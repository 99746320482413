<script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
<div class="content" style="zoom:80%">
  <div class="row">
    <div class="column" style="float:left; max-width:45%; padding-left:30px;">
      <label class="label">User Activity</label>
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr class="is-selected">
            <th>User</th>
            <th>Start Date</th>
            <th>Last Login</th>
            <th>Bonds</th>
            <th>Shareholders</th>
            <th>Redeem/Invest</th>
            <th>Expenses</th>
            <th>Deletes</th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of actData; let i = index">
            <td>{{dataItem.username}}</td>
            <td>{{dataItem.start.toString().slice(0, 10)}}</td>
            <td>{{dataItem.lastLogin.toString().slice(0, 16).replace("T"," ")}}</td>
            <td>{{dataItem.bonds}}</td>
            <td>{{dataItem.shareholders}}</td>
            <td>{{dataItem.redOrInvest}}</td>
            <td>{{dataItem.expenses}}</td>
            <td>{{dataItem.deletes}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column" style="float:left; max-width:25%; padding-left:50px">
      <label class="label">Platform Users</label>
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr class="is-selected">
            <th>User</th>
            <th>Admin</th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of usData; let i = index">
            <td>{{dataItem.ozer}}</td>
            <td>{{dataItem.adle}}</td>
            <td><input type="button" (click)="deleteUser(dataItem);" value="X"
                style="background:none;border:none; color:#00d1b2"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column" style="float:left; max-width:25%; padding-left:50px">
      <form [formGroup]="newUserForm" (ngSubmit)="onNewSubmit()" class="form-inline">
        <div style="width: 100%; padding-top: 30px; padding-bottom: 30px; padding-left: 50px;">
          <label class="label">Add New User</label>
          <!--<div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">-->
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <input id="radButt" formControlName="admin" class="form-control" type="checkbox" placeholder="Admin"
                  value="true" />Admin
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group pl-2">
                <input formControlName="username" class="form-control" type="text" placeholder="Username"
                  [ngClass]="{ 'is-invalid': submittednew && e.username.errors }">
                <div *ngIf="submittednew && e.username.errors" class="invalid-feedback">
                  <div *ngIf="e.username.errors.required">Username is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group pl-2">
                <input formControlName="firstname" class="form-control" type="text" placeholder="First Name"
                  [ngClass]="{ 'is-invalid': submittednew && e.firstname.errors }">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group pl-2">
                <input formControlName="surname" class="form-control" type="text" placeholder="Surname"
                  [ngClass]="{ 'is-invalid': submittednew && e.surname.errors }">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group pl-2">
                <input formControlName="email" class="form-control" type="email" placeholder="email@griffon.solutions"
                  [ngClass]="{ 'is-invalid': submittednew && e.email.errors }">
                <!--<div *ngIf="submittednew && e.username.errors" class="invalid-feedback">
                  <div *ngIf="e.username.errors.required">Username is required</div>
                </div>-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group pl-2">
                <input formControlName="password" class="form-control" type="password" placeholder="Password"
                  [ngClass]="{ 'is-invalid': submittednew && e.password.errors }">
                <div *ngIf="submittednew && e.password.errors" class="invalid-feedback">
                  <div *ngIf="e.password.errors.required">password is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group pl-2">
                <input formControlName="password2" class="form-control" type="password" placeholder="Confirm Password"
                  [ngClass]="{ 'is-invalid': submittednew && e.password2.errors }">
                <div *ngIf="submittednew && e.password2.errors" class="invalid-feedback">
                  <div *ngIf="e.password2.errors.required">confirm password</div>
                </div>
                <div *ngIf="matchup == false" style="color:#dc3545; font-size:80%; margin-top: .25rem; width: 100%;">
                  passwords don't match</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <button class="btn btn-success">Add User</button>
            </div>
          </div>
          <!--</div>-->
        </div>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="column" style="float:left; max-width:25%; padding-left:50px">
      <label class="label">Public Holidays for {{year}}</label>
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr class="is-selected">
            <th>Holiday</th>
            <th>Date</th>
            <th>Recurring</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let hol of holData; let i = index">
            <td>{{hol.name}}</td>
            <td>{{hol.date.toString().slice(0, 10)}}</td>
            <td>{{hol.recur?'Yes':'No'}}</td>
            <td>
              <!--<input type="button" (click)="editHoliday(hol);" value="&#9998;" style="background:none;border:none; color:#00d1b2">-->
            </td>
            <td>
              <!--<input type="button" (click)="deleteHoliday(hol);" value="X" style="background:none;border:none; color:#00d1b2">-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>