<table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
  <thead>
    <tr class="is-selected">
      <th></th>
      <th>Nav Date</th>
      <!--<th>Prior Nav Date</th>-->
      <th>No of Days</th>
      <th>Accrued Interest</th>
      <th>Administration Fee Expense</th>
      <th>Management Fee</th>
      <th>Organisational Expenses</th>
      <th>Total Expense</th>
      <th>Net Other Income</th>
      <th>Expense Part Shares (28 bps)</th>
      <th>Expense Part Shares (18 bps)</th>
      <th>P/L Allocation (28 bps)</th>
      <th>P/L Allocation (18 bps)</th>
      <th>Allocation P/L (Total)</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let dataItem of PLData; let i = index">
      <th>{{i + 1}}</th>
      <td>{{dataItem.navDate.slice(0, -9)}}</td>
      <!--<td>{{dataItem.priorNAVDate.slice(0, -9)}}</td>-->
      <td>{{dataItem.days}}</td>
      <td>{{dataItem.accInt.toFixed(2)}}</td>
      <td>{{dataItem.adminFee.toFixed(2)}}</td>
      <td>{{dataItem.managementFee.toFixed(2)}}</td>
      <td>{{dataItem.orgExpense.toFixed(2)}}</td>
      <td>{{dataItem.totalExpense.toFixed(2)}}</td>
      <td>{{dataItem.netOtherIncome.toFixed(2)}}</td>
      <td>{{dataItem.expenseePartShare28.toFixed(2)}}</td>
      <td>{{dataItem.expenseePartShare18.toFixed(2)}}</td>
      <td>{{dataItem.plAll18.toFixed(2)}}</td> <!--needs to be switched around api side-->
      <td>{{dataItem.plAll28.toFixed(2)}}</td>
      <td>{{dataItem.total.toFixed(2)}}</td>
    </tr>
  </tbody>
</table>

<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>