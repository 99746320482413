<div class="tab">
  <ul class="main_tab">
    <li (click)="activeBtn(1)" [class]="CurrentTab === 1 ? 'active' : ''">Old Mutual US Dollar Money Market Fund</li>
    <li (click)="activeBtn(2)" [class]="CurrentTab === 2 ? 'active' : ''">Old Mutual US Dollar Duration Fund</li>
  </ul>
</div>

<div class="content">
  <div class="row">
    <div class="column" style="float:left; max-width:35%; padding-left:50px">
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr class="is-selected">
            <th>{{date}}</th>
            <th>Class C</th>
            <th>Participating</th>
            <!--<th>No. of Days</th>-->
            <th>Total</th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
            <th id="sumExp"></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of rows; let i = index">
            <ng-container *ngIf="dataItem != 'description' && dataItem != 'navDate'">
              <th>{{dataItem}}</th>
              <td>{{classC[dataItem].toFixed(2)}}</td>
              <td>{{part[dataItem].toFixed(2)}}</td>
              <td>{{(classC[dataItem]+part[dataItem]).toFixed(2)}}</td>
            </ng-container>
            <!--<ng-container *ngIf="dataItem == 'navDate'">
            <th>{{dataItem}}</th>
            <td>{{classC[dataItem].slice(0, -9)}}</td>
            <td>{{part[dataItem].slice(0, -9)}}</td>
            <td>{{classC[dataItem].slice(0, -9)}}</td>
          </ng-container>-->
          </tr>
        </tbody>
      </table>
    </div>
    <!--<div class="column" style="float:left; width:33.33%;padding-left:50px">
    <div style="width: 500px; padding-top: 30px; ">
      <label class="label">Please insert additional expense below.</label>
      <div class="field ">
        <div class="control">
          <input class="input" type="text" placeholder="Expense Description">
        </div>
      </div>
      <div>

      </div>
      <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">
        <div class="control">
          <input class="input" type="text" placeholder="Currency">
        </div>
        <div class="control">
          <input class="input" type="text" placeholder="Amount" style="width:295px">
        </div>
      </div>
      <div class="field ">
        <div class="control">
          <input class="input" type="text" placeholder="Days">
        </div>
      </div>

      <div class="buttons is-right are-small" style="padding-top: 10px">
        <button class="button is-success">Add Expense</button>
      </div>
    </div>
  </div>-->
    <div class="column" style="float:left;  padding-left:50px">
      <div>

        <form [formGroup]="AuditForm" (ngSubmit)="onAuditSubmit()" style="padding-left:10px">
          <!--<div class="column" style="float:left; width:33.33%">-->
          <label class="label" style="padding-top: 18px; padding-bottom: 18px; padding-left: 10px">Report Date
            Range</label>
          <div class="row " style="float:left; width: 100%; padding-left: 30px">
            <div>
              <label class="label">Start Date</label>
              <div class="form-group ">
                <input formControlName="fromDate" class="form-control" type="date" placeholder="Date" min="2020-11-30"
                  max="{{date}}" [ngClass]="{ 'is-invalid': auditSubmitted && audit.fromDate.errors }">
                <div *ngIf="auditSubmitted && audit.fromDate.errors" class="invalid-feedback">
                  <div *ngIf="audit.fromDate.errors.required">Date is required</div>
                </div>
              </div>
            </div>
            <div>
              <label class="label">End Date</label>
              <div class="form-group " style="padding-left: 10px">
                <input formControlName="toDate" class="form-control" type="date" placeholder="Date"
                  min="{{audit.fromDate.value}}" max="{{date}}"
                  [ngClass]="{ 'is-invalid': auditSubmitted && audit.toDate.errors }">
                <div *ngIf="auditSubmitted && audit.toDate.errors" class="invalid-feedback">
                  <div *ngIf="audit.toDate.errors.required">Date is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row " style="float: left; width: 100%; padding-left: 30px">
            <div class="buttons are-small">
              <button class="btn btn-success" (click)="download();" [disabled]="btnDownloadSeriesLoader">
                {{ btnDownloadSeriesLoader ? "Loading" : "Download P&L by series.xlsx"}}
              </button>
            </div>
            <div class="buttons are-small" style="padding-left: 10px; padding-bottom:25px">
              <button class="btn btn-success" (click)="downloadYTD();" [disabled]="btnDownloadYTDLoader">
                {{ btnDownloadYTDLoader ? "Loading" : "Download P&L YTD.xlsx" }}
              </button>
            </div>
          </div>

          <div class="row " style="float: left; width: 100%; padding-left: 30px; margin-bottom: 50px;">
            <div class="buttons are-small" style="margin-bottom: 5px">
              <button class="btn btn-success" (click)="downloadBond();" [disabled]="btnBondLoader">
                {{ btnBondLoader ? "Loading" : "Bond Accruals.xlsx" }} 
              </button>
            </div>
            <div class="buttons are-small" style="padding-left: 10px; margin-bottom: 5px">
              <button class="btn btn-success" (click)="downloadShareholders();" [disabled]="btnShareholderLoader">
                {{ btnShareholderLoader ? "Loading" : "ShareHolders.xlsx" }}
              </button>
            </div>
            <div class="buttons are-small" style="padding-left: 10px; margin-bottom: 5px">
              <button class="btn btn-success" (click)="downloadBalanceSheet();" [disabled]="btnBalanceSheetLoader">
                {{ btnBalanceSheetLoader ? "Loading" : "Balance Sheet.xlsx" }} 
              </button>
            </div>
          </div>
          <!--</div>-->
        </form>
      </div>
      <div style="margin-left: 25px;">
        <form [formGroup]="uploadForm" (ngSubmit)="onUpload()">
          <label class="label" style="padding-top: 18px; padding-bottom: 18px; padding-left: 10px">Additional
            Reports</label>
          <select formControlName="report" name="select" id="select" [(ngModel)]="report">
            <option [ngValue]="undefined" disabled>--Report Type--</option>
            <option [ngValue]="1"> Valuation Report </option>
            <option [ngValue]="2"> Yield Report </option>
            <option [ngValue]="3"> FactSheet Report </option>
          </select>

          <!-- Show/hide based on the selected value in the first dropdown -->
          <select *ngIf="report === 1" name="select" id="select" [(ngModel)]="shareholderId"
            formControlName="shareholderId" style="margin-left: 30px;">
            <option [ngValue]="undefined" disabled>--User Select--</option>
            <option *ngFor="let item of shareholderData" [ngValue]="item.id">
              {{item.name}}
            </option>
          </select><br /><br />

          <div *ngIf="report !== 1 && report!==undefined">
            <label>Upload Document : </label>
            <input type="file" (change)="onFileSelected($event)" #myInput formControlName="file" />
            <p style="color: red;">{{ filevalid ? "Invalid file type. Only Excel, Word, and PDF files are allowed." : ""
              }}
            </p>
          </div>

          <button class="btn btn-success" [disabled]="(report === 1) ?
             (!uploadForm.value.shareholderId || !uploadForm.value.report) :
             !uploadForm.value.report || !selectedFile">{{
            isSubmit ? "Loading..." : "Generate" }}</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>