import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavdataComponent } from './navdata/navdata.component';
import { ShowDataComponent } from './navdata/show-data/show-data.component';
import { ApiService } from './api.service';
import { ProfitLossComponent } from './Profit-loss/profit-loss.component';
import { BondAcruelComponent } from './bond-acruel/bond-acruel.component';
import { ShareholderComponent } from './Shareholder/shareholder.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { AuditComponent } from './audit/audit.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AlertComponent } from './_components/alert.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { fakeBackendProvider } from './_helpers/fake-backend';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpModule } from '@angular/http';
import { AdminLogComponent } from './admin-log/admin-log.component'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AdminComponent } from './admin/admin.component';
import { ExecComponent } from './exec/exec.component';


@NgModule({
  declarations: [
    AppComponent,
    NavdataComponent,
    ShowDataComponent,
    ProfitLossComponent,
    BondAcruelComponent,
    ShareholderComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ExpensesComponent,
    AuditComponent,
    LoginComponent,
    RegisterComponent,
    AlertComponent,
    DashboardComponent,
    AdminLogComponent,
    AdminComponent,
    ExecComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    FormsModule
  ],
  providers: [ApiService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }
