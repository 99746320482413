<div class="content" style="zoom:80%">
  <div class="row" style="padding-right:0px">
    <div class="column" style="float:left; padding-left:30px;max-width: 45%;">
      <label class="label">Bond Updates</label>
      <table class="table is-bordered is-striped is-narrow is-hoverable" style="max-width: 45%;">
        <thead>
          <tr class="is-selected">
            <th>Uploaded</th>
            <th>User</th>
            <!--<th>Action</th>-->
            <th>Security</th>
            <th>Contract</th>
            <th>Amount</th>
            <th>Rate</th>
            <th>Start</th>
            <th>Maturity</th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <!--<th></th>-->
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of bondData; let i = index">
            <td>{{dataItem.upDate.toString().slice(2, 10)}}</td>
            <td>{{dataItem.user}}</td>
            <!--<td>{{dataItem.editType}}</td>-->
            <td>{{dataItem.security}}</td>
            <td>{{dataItem.contract}}</td>
            <td>{{dataItem.amount.toFixed(2)}}</td>
            <td>{{dataItem.rate}}</td>
            <td>{{dataItem.startDate.toString().slice(2, 10)}}</td>
            <td>{{dataItem.maturityDate.toString().slice(2, 10)}}</td>
            <td><input *ngIf="checkUser(dataItem.user)" type="button" (click)="deleteBond(dataItem);" value="X"
                style="background:none;border:none; color:#00d1b2"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column" style="padding-left:50px; padding-right:10px; right:0; float:right;">
      <label class="label">Shareholder Updates</label>
      <table class="table is-bordered is-striped is-narrow is-hoverable" style="max-width: 45%;">
        <thead>
          <tr class="is-selected">
            <th>Uploaded</th>
            <th>User</th>
            <th>Action</th>
            <th>Shareholder</th>
            <th>Date</th>
            <th>New Par Value</th>
            <th>New Share Prem</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of redData; let i = index">
            <td>{{dataItem.editDate.toString().slice(0, 10)}}</td>
            <td>{{dataItem.user}}</td>
            <td>{{dataItem.action}}</td>
            <td>{{dataItem.num}}</td>
            <td>{{dataItem.upDate.toString().slice(0, 10)}}</td>
            <td>{{dataItem.newParValue.toFixed(2)}}</td>
            <td>{{dataItem.newSharePremium.toFixed(2)}}</td>
            <td>{{dataItem.amount.toFixed(2)}}</td>
            <td><input *ngIf="checkUser(dataItem.user)" type="button" (click)="deleteRed(dataItem);" value="X"
                style="background:none;border:none; color:#00d1b2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="column" style="float:left; max-width:45%; padding-left:30px;">
      <label class="label">Expense Updates</label>
      <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr class="is-selected">
            <th>Uploaded</th>
            <th>User</th>
            <th>Type</th>
            <th>Expense</th>
            <th>Class</th>
            <th>Amount</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Days</th>
            <th></th>
          </tr>
        </thead>
        <tfoot>
          <tr class="is-selected">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
        <tbody>
          <tr *ngFor="let dataItem of expData; let i = index">
            <td>{{dataItem.uploaded.toString().slice(0, 10)}}</td>
            <td>{{dataItem.user}}</td>
            <td>{{dataItem.type}}</td>
            <td>{{dataItem.description}}</td>
            <td>{{dataItem.class}}</td>
            <td>{{dataItem.amount.toFixed(2)}}</td>
            <td>{{dataItem.startDate.toString().slice(0, 10)}}</td>
            <td>{{dataItem.endDate.toString().slice(0, 10)}}</td>
            <td>{{dataItem.days}}</td>
            <td><input type="button" (click)="deleteExp(dataItem);" value="X"
                style="background:none;border:none; color:#00d1b2"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column" style="float:left; max-width:25%; padding-left:50px">
      <label class="label">User Information</label>
      <form [formGroup]="detailsForm" (ngSubmit)="updateSubmit()" class="form-inline">
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group focused">
                <label class="form-control-label" for="input-username">Username</label>
                <input type="text" formControlName="username" id="input-username"
                  class="form-control form-control-alternative" placeholder="Username" value="{{detailsData.username}}"
                  readonly>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label" for="input-email">Email address</label>
                <input type="email" formControlName="email" id="input-email"
                  class="form-control form-control-alternative" placeholder="nav@dma.co.za"
                  value="{{detailsData.email}}">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group focused">
                <label class="form-control-label" for="input-first-name">First name</label>
                <input type="text" formControlName="firstname" id="input-first-name"
                  class="form-control form-control-alternative" placeholder="First name"
                  value="{{detailsData.firstname}}">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group focused">
                <label class="form-control-label" for="input-last-name">Last name</label>
                <input type="text" formControlName="surname" id="input-last-name"
                  class="form-control form-control-alternative" placeholder="Last name" value="{{detailsData.surname}}">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group focused">
                <button class="btn btn-success">Update Info</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="column" style="float:left; max-width:25%; padding-left:50px">
      <form [formGroup]="passwordForm" (ngSubmit)="updatePassword()" class="form-inline">
        <div style="width: 100%; padding-top: 30px; padding-bottom: 30px; padding-left: 50px;">
          <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group pl-2">
                  <label class="form-control-label" for="input-last-name">Change Password</label>
                  <input formControlName="password" class="form-control" type="password" placeholder="Password"
                    [ngClass]="{ 'is-invalid': submittednew && pass.password.errors }">
                  <div *ngIf="submittednew && pass.password.errors" class="invalid-feedback">
                    <div *ngIf="pass.password.errors.required">Username is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group pl-2">
                  <input formControlName="newpassword" class="form-control" type="password" placeholder="New Password"
                    [ngClass]="{ 'is-invalid': submittednew && pass.newpassword.errors }">
                  <div *ngIf="submittednew && pass.newpassword.errors" class="invalid-feedback">
                    <div *ngIf="pass.newpassword.errors.required">password is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group pl-2">
                  <input formControlName="newpassword2" class="form-control" type="password"
                    placeholder="Confirm New Password"
                    [ngClass]="{ 'is-invalid': submittednew && pass.newpassword2.errors }">
                  <div *ngIf="submittednew && pass.newpassword2.errors" class="invalid-feedback">
                    <div *ngIf="pass.newpassword2.errors.required">confirm password</div>
                  </div>
                  <div *ngIf="matchup == false" style="color:#dc3545; font-size:80%; margin-top: .25rem; width: 100%;">
                    passwords don't match</div>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group pl-2">
                <button class="btn btn-success">Change Password</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>