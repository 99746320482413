import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../_models/user';

@Component({
  selector: 'app-exec',
  templateUrl: './exec.component.html',
  styleUrls: ['./exec.component.css', '/node_modules/bulma/css/bulma.css']
})
export class ExecComponent implements OnInit {
  newUserForm: FormGroup;
  submittednew = false;
  loading: boolean;
  matchup: boolean;
  currentUser: User;
  isLoader: boolean = true;
  constructor(private service: ApiService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService) { this.authenticationService.currentUser.subscribe(x => this.currentUser = x); }

  actData: any = [];
  bondData: any = [];
  usData: any = [];
  holData: any = [];
  year: any;

  ngOnInit(): void {
    this.refreshData();
    this.newUserForm = this.formBuilder.group({
      admin: [''],
      username: ['', Validators.required],
      firstname: [''],
      surname: [''],
      email: [''],
      password: ['', Validators.required],
      password2: ['', Validators.required]
    })
  }
  refreshData() {
    this.service.getActivity().subscribe(acdata => {
      this.isLoader = false
      this.actData = acdata;
    });
    this.service.getAd().subscribe(adata => {
      this.isLoader = false
      this.usData = adata;
    });
    this.service.getHols().subscribe(hdata => {
      this.isLoader = false
      this.holData = hdata;
      this.year = hdata[0].date.toString().slice(0, 5);
    });
    //this.year = this.holData[0].date.toString().slice(0, 5);
  }

  get e() { return this.newUserForm.controls; }

  onNewSubmit() {
    this.submittednew = true;
    this.matchup = this.e.password.value == this.e.password2.value ? true : false;

    // stop here if form is invalid
    if (this.newUserForm.invalid || !this.matchup) {
      console.log("invalid form");
      return;
    }

    this.loading = true;

    this.service.addUser({ 'username': this.e.username.value, 'firstname': this.e.firstname.value, 'surname': this.e.surname.value, 'email': this.e.email.value, 'password': this.e.password.value, 'role': Boolean(this.e.admin.value) })
      .pipe().subscribe(reply => {
        console.log(reply)
        this.refreshData();
        window.alert(reply.message);
        this.newUserForm.reset();
        Object.keys(this.newUserForm.controls).forEach(key => {
          this.newUserForm.controls[key].setErrors(null);
        });
      },
        error => {
          console.log(error);
          window.alert('Failed to add user: ' + error);
        }
      );
  }

  public deleteUser(item) {
    var deleteOzer = window.confirm('Are you sure you want to delete user: ' + item.ozer + '?');
    if (deleteOzer) {
      this.service.deleteUser(item.ozer).pipe().subscribe(reply => {
        console.log(reply);
        this.refreshData();
        window.alert(reply.message);
      });
    }
  }

  public editHoliday(item) {
    console.log(item);
    var newDate = new Date(window.prompt('Please enter new date for ' + item.name + ': ')).toISOString();
    console.log(newDate);
  }
  public deleteHoliday(item) {
    console.log(item);
  }

}
