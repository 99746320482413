<div class="row1"> <!--style="zoom:85%"-->
  <div class="tab">
    <ul class="main_tab">
      <li (click)="activeBtn(1)" [class]="CurrentTab === 1 ? 'active' : ''">Old Mutual US Dollar Money Market Fund</li>
      <li (click)="activeBtn(2)" [class]="CurrentTab === 2 ? 'active' : ''">Old Mutual US Dollar Duration Fund</li>
    </ul>
  </div>

  <div class="form-inline">
    <form [formGroup]="BondForm" (ngSubmit)="onSubmit()">
      <div style="width: 100%; padding-top: 10px; padding-bottom: 5px; padding-left: 30px;">
        <label class="label" style="font-size: large;">Add New Bond</label>
        <div class="field " style="display:flex; flex-flow:row wrap; align-items: normal">

          <div class="form-group pr-1 pb-6 pt-4">
            <div style="float:left">
              <label class="label">Activation Date</label>
              <input formControlName="update" class="form-control" type="date" placeholder="Today's Date"
                [ngClass]="{ 'is-invalid': submitted && f.update.errors }">
            </div>
            <div *ngIf="submitted && f.update.errors" class="invalid-feedback">
              <div *ngIf="f.update.errors.required">Today's date is required</div>
            </div>
          </div>
          <div class="form-group pl-2">
            <input formControlName="contract" class="form-control" type="text" placeholder="Contract ID"
              [ngClass]="{ 'is-invalid': submitted && f.contract.errors }">
            <div *ngIf="submitted && f.contract.errors" class="invalid-feedback">
              <div *ngIf="f.contract.errors.required">Contract is required</div>
            </div>
          </div>
          <div class="form-group pl-2">
            <input formControlName="amount" class="form-control" type="number" step="0.01" placeholder="Amount"
              [ngClass]="{ 'is-invalid': submitted && f.amount.errors }">
            <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
              <div *ngIf="f.amount.errors.required">Amount is required</div>
            </div>
          </div>
          <div class="form-group pl-2">
            <input formControlName="rate" class="form-control" type="number" step="0.01" placeholder="Rate"
              [ngClass]="{ 'is-invalid': submitted && f.rate.errors }">
            <div *ngIf="submitted && f.rate.errors" class="invalid-feedback">
              <div *ngIf="f.rate.errors.required">Rate is required</div>
            </div>
          </div>
          <div class="form-group pl-2 ">
            <select class="form-control" formControlName="security"
              [ngClass]="{ 'is-invalid': submitted && f.security.errors }" [(ngModel)]="securityvalue">
              <option [ngValue]="undefined" disabled>Please select a Security</option>
              <option *ngFor="let security of security">{{security}}</option>
            </select>
            <div *ngIf="submitted && f.security.errors" class="invalid-feedback">
              <div *ngIf="f.security.errors.required">Security is required</div>
            </div>
          </div>

          <div class="form-group pl-2 pr-1 pb-6 pt-4">
            <div style="float:left">
              <label class="label">Start Date</label>
              <input formControlName="startDate" class="form-control" type="date" placeholder="Start Date"
                [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }">
            </div>
            <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
              <div *ngIf="f.startDate.errors.required">Start Date is required</div>
            </div>
          </div>
          <div class="form-group pl-2 pr-1 pb-6 pt-4">
            <div style="float:left">
              <label class="label">Reset Date</label>
              <input formControlName="resetDate" class="form-control" type="date" placeholder="Reset Date"
                [ngClass]="{ 'is-invalid': submitted && f.resetDate.errors }">
            </div>
            <div *ngIf="submitted && f.resetDate.errors" class="invalid-feedback">
              <div *ngIf="f.resetDate.errors.required">Reset Date is required</div>
            </div>
          </div>
          <div class="form-group pl-2 pb-6 pt-4 pr-2">
            <div style="float:left">
              <label class="label">Maturity Date</label>
              <input formControlName="maturitydate" class="form-control" type="date" placeholder="Maturity Date"
                [ngClass]="{ 'is-invalid': submitted && f.maturitydate.errors }">
            </div>
            <div *ngIf="submitted && f.maturitydate.errors" class="invalid-feedback">
              <div *ngIf="f.maturitydate.errors.required">Maturity Date is required</div>
            </div>
          </div>
          <div class="form-group pl-2 pb-6 pt-4 pr-2">
            <div float:left>
              <label class="label" style="color:white">button</label>
              <button class="btn btn-success">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Add Bond
              </button>
            </div>

          </div>
        </div>

      </div>
    </form>
    <button class="btn btn-success" style="margin-left: 15px; margin-top: 40px;" (click)="ForeclosureBond()">
      Foreclosure
    </button>
    <button class="btn btn-success" style="margin-left: 15px; margin-top: 40px;" (click)="UpdateBond()">
      Update Bond
    </button>
  </div>
</div>
<!--<button class="btn btn-success" (click)="downloadReport();">Download Bond Accruals.xlsx</button>-->

<div class=" row">
  <div class=" col-12">
    <div class=" card card-chart">
      <!--<div class=" card-header">
          <h2 class=" card-title">{{shareholder}}</h2>
        </div>-->
      <div class=" card-body">
        <div class=" chart-area"><canvas id="bondChart"> </canvas></div>
      </div>
    </div>
  </div>
</div>

<table class="table is-bordered is-striped is-narrow is-hoverable">
  <thead>
    <tr class="is-selected">
      <th></th>
      <th>Nav Date</th>
      <th>CCY</th>
      <!--<th>Ref/Contract No.</th>-->
      <th>
        <select (change)="filter($event);"
          style="background-color:#00d1b2;color:white;border-color:#00d1b2;font-weight:bold">
          <option style="background-color:white;color:black;border-color:#00d1b2;" value="all">Ref/Contract No.</option>
          <option style="background-color:white;color:black;border-color:#00d1b2;" *ngFor="let cont of contractData">
            {{cont}}</option>
        </select>
      </th>
      <th>Accrued Days</th>
      <th>Total Days</th>
      <th>Remaining Days</th>
      <!--<th>Accrued Interest to be earned</th>-->
      <th>Accrued Interest at Maturity</th>
      <th>Accrued Interest (Per Day)</th>
      <th>Accrued Interest YTD</th>
    </tr>
  </thead>
  <tfoot>
    <tr class="is-selected">
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th id="sumInt"></th>
      <th id="sumIntPd"></th>
      <th id="sumYTD"></th>
    </tr>
  </tfoot>
  <tbody>
    <tr *ngFor="let dataItem of AccData; let i = index">
      <th>{{i + 1}}</th>
      <td>{{dataItem.navDate.slice(0, -9)}}</td>
      <td>USD</td>
      <td>{{dataItem.fundID}}  <span style="color: red" *ngIf="dataItem.isForeclosure">closed</span></td>
      <td>{{dataItem.accruedDays}}</td>
      <td>{{dataItem.totalDays}}</td>
      <td>{{dataItem.remDays}}</td>
      <!--<td>{{(dataItem.accIntMatured-dataItem.accYTD).toFixed(2)}}</td>-->
      <td>{{dataItem.accIntMatured.toFixed(2)}}</td>
      <td>{{dataItem.accIntPerDay.toFixed(2)}}</td>
      <td>{{dataItem.accYTD.toFixed(2)}}</td>
    </tr>
  </tbody>
</table>

<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>