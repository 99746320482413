import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../_models/user';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['/node_modules/bulma/css/bulma.css']
})
export class AdminComponent implements OnInit {
  passwordForm: FormGroup;
  uloadfile: FormGroup;
  detailsForm: FormGroup;
  submittednew = false;
  loading: boolean;
  matchup: boolean;
  currentUser: User;
  username: string;
  isLoader: boolean = true;
  // @ViewChild('myInput')
  // myInputVariable: ElementRef;

  constructor(private service: ApiService,
    private formBuilder: FormBuilder, private alertService: AlertService,
    private authenticationService: AuthenticationService) { this.authenticationService.currentUser.subscribe(x => this.currentUser = x); }

  redData: any = [];
  bondData: any = [];
  usData: any = [];
  expData: any = [];
  detailsData: any = [];

  ngOnInit(): void {
    this.refreshData();
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      newpassword: ['', Validators.required],
      newpassword2: ['', Validators.required]
    });
    this.detailsForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      firstname: ['', Validators.required],
      surname: ['', Validators.required]
    });

  }

  refreshData() {
    this.service.getUserDetails().subscribe(ddata => {
      this.isLoader = false
      this.detailsData = ddata;
      this.username = this.detailsData.username;
    });
    this.service.getReds(10).subscribe(rdata => {
      this.isLoader = false
      this.redData = rdata;
    });
    this.service.getBondUpdates(10).subscribe(bdata => {
      this.isLoader = false
      this.bondData = bdata;
    });
    this.service.getAd().subscribe(adata => {
      this.isLoader = false
      this.usData = adata;
    });
    this.service.getExps().subscribe(edata => {
      this.isLoader = false
      this.expData = edata;
    });
  }

  checkUser(name): boolean {
    return this.username == name || this.currentUser.role;
  }

  get pass() { return this.passwordForm.controls; }
  get deets() { return this.detailsForm.controls; }

  updateSubmit() {
    console.log(this.detailsForm);
    console.log(this.deets);
    if (this.detailsForm.pristine) {
      console.log("no update");
      return;
    }
    var usernameChange = this.deets.username.value == '' || this.deets.username.value == this.detailsData.username.toString() ? '' : ('Username: ' + this.detailsData.username.toString() + ' -> ' + this.deets.username.value + '\n');
    var firstnameChange = this.deets.firstname.value == '' || this.deets.firstname.value == this.detailsData.firstname.toString() ? '' : ('Firstname: ' + this.detailsData.firstname.toString() + ' -> ' + this.deets.firstname.value + '\n');
    var surnameChange = this.deets.surname.value == '' || this.deets.surname.value == this.detailsData.surname.toString() ? '' : ('Surname: ' + this.detailsData.surname.toString() + ' -> ' + this.deets.surname.value + '\n');
    var emailChange = this.deets.email.value == '' || this.deets.email.value == this.detailsData.email.toString() ? '' : ('Email: ' + this.detailsData.email.toString() + ' -> ' + this.deets.email.value + '\n');
    if (usernameChange != '' || firstnameChange != '' || surnameChange != '' || emailChange != '') {
      var update = window.confirm('Are you sure you want to update the following:\n' + usernameChange + firstnameChange + surnameChange + emailChange);
      if (update) {
        this.service.updateUserDetails({ 'username': this.deets.username.value == '' ? this.detailsData.username.toString() : this.deets.username.value, 'firstname': this.deets.firstname.value, 'surname': this.deets.surname.value, 'email': this.deets.email.value })
          .pipe().subscribe(reply => {
            console.log(reply);
            window.alert(reply.message);
            this.refreshData();
          }, error => {
            console.log(error);
            window.alert('Failed to Update User Details:' + error);
          });
      }
    }

  }

  updatePassword() {
    this.submittednew = true;
    this.matchup = this.pass.newpassword.value == this.pass.newpassword2.value;

    // stop here if form is invalid
    if (this.passwordForm.invalid || !this.matchup) {
      console.log("invalid form");
      return;
    }

    this.loading = true;

    this.service.updateUserPassword({ 'username': this.detailsData.username, 'password': this.pass.password.value, 'newpassword': this.pass.newpassword.value })
      .pipe().subscribe(reply => {
        console.log(reply);
        window.alert(reply.message);
        this.refreshData();
        this.passwordForm.reset();
        Object.keys(this.passwordForm.controls).forEach(key => {
          this.passwordForm.controls[key].setErrors(null);
        });
      }, error => {
        console.log(error);
        window.alert('Failed to Save new password: ' + error);
        this.refreshData();
      });
  }

  public deleteBond(item) {
    var deleteItem = window.confirm('Are you sure you want to delete Bond: ' + item.contract + '?');
    if (deleteItem) {
      this.service.deleteBond(item).pipe().subscribe(reply => {
        console.log(reply);
        this.refreshData();
        window.alert(reply.message);
      }, error => {
        console.log(error);
        window.alert('Failed to Delete Bond: ' + error);
        this.refreshData();
      });
    }
  }
  public deleteRed(item) {
    var deleteItem = window.confirm('Are you sure you want to delete the ' + item.action + ' of $' + (item.action == 'Redemption' ? -1 * item.amount.toFixed(2) : item.amount.toFixed(2)) + ' for Shareholder ' + item.num + '?');
    if (deleteItem) {
      if (item.action == 'Initial Investment') {
        this.service.deleteShare(item).pipe().subscribe(reply => {
          console.log(reply);
          this.refreshData();
          window.alert(reply.message);
        }, error => {
          console.log(error);
          window.alert('Failed to Delete Initial Investment: ' + error);
          this.refreshData();
        });
      } else if (item.action == 'Redemption' || item.action == 'Investment') {
        this.service.deleteRed(item).pipe().subscribe(reply => {
          console.log(reply);
          this.refreshData();
          window.alert(reply.message);
        }, error => {
          console.log(error);
          window.alert('Failed to Delete ' + item.action + ': ' + error);
          this.refreshData();
        });
      }
    }
  }
  public deleteExp(item) {
    var deleteItem = window.confirm('Are you sure you want to delete the Expense' + item.expense + '?');
    if (deleteItem) {
      this.service.deleteExp(item).pipe().subscribe(reply => {
        console.log(reply);
        this.refreshData();
        window.alert(reply.message);
      });
    }
  }
  public updateDetails(item) {

  }
  public updatePaswword(item) {

  }
}
