import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Http, ResponseContentType, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { Body } from '@angular/http/src/body';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  readonly APIUrl = environment.baseUrl + '/api'; //"http://localhost:5000";

  constructor(private http: HttpClient, private httpp: Http,
    private authenticationService: AuthenticationService) { }

  getDBProfit(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/dashboard/profit', { headers });
  }
  getAd(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/us', { headers });
  }
  getHols(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/holidays', { headers });
  }
  getUserDetails(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/userdetails', { headers });
  }
  getActivity(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/activity', { headers });
  }
  getExps(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/expenses', { headers });
  }
  getShareHolder(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/User/GetShareholderUsersInfo', { headers });
  }
  getCategory(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/Category/GetAll', { headers });
  }
  getReds(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/' + num + 'reds', { headers });
  }
  getRedsFilter(num, fundType): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/' + num + 'reds/' + fundType, { headers });
  }
  getBondUpdates(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/' + num + 'bonds', { headers });
  }
  getBondUpdatesFilter(num, fundType): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/admin/' + num + 'bonds/' + fundType, { headers });
  }
  ShareholderPerHolding(fundType): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/Shareholder/ShareholderPerHolding/' + fundType, { headers });
  }
  updateUserDetails(details) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/update/userdetails', details, { headers });
  }
  uploadFile(id, report, fundType, data) {
    const formData = new FormData();
    formData.append('file', data);
    let datapass = report === "1" ? `/SystemDrive/Upload/${report}/${fundType}/${id}` : `/SystemDrive/Upload/${report}/${fundType}`
    return this.http.post<any>(this.APIUrl + datapass, formData);
  }
  GenerateValueGainReport(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Reporting/GenerateValueGainReport', body, { headers });
  }
  updateUserPassword(passwords) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/update/userpassword', passwords, { headers });
  }
  deleteBond(bond) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/bond', bond, { headers });
  }
  deleteRed(redemp) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/red', redemp, { headers });
  }
  deleteShare(redemp) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/share', redemp, { headers });
  }
  deleteExp(expense) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/expense', expense, { headers });
  }
  addUser(user) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/add/user', user, { headers });
  }
  deleteUser(user) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/admin/delete/user', JSON.stringify(user), { headers });
  }
  getNavData(data): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + `/NAVData/${data.contract}?fundType=${data.currenttab}`, { headers });
  }
  getNavDataLine(data): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + `/NAVData/line/${data.contract}?fundType=${data.currenttab}`, { headers });
  }
  getBondContracts(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata', { headers });
  }
  getExpBonds(fundType): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/expiring/' + fundType, { headers });
  }
  getPerfBonds(fundType): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/performing/' + fundType, { headers });
  }
  getDBbonds(fundType): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/navdata/dbData/' + fundType, { headers });
  }
  getPLData(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/profitLoss', { headers });
  }
  getShareholderNums(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder', { headers });
  }
  getSequentialNumbers(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/Shareholder/GetSequentialNumbers', { headers });
  }
  getShareholder(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/' + num, { headers });
  }
  getShareholderList(): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/GetShareholders/', { headers });
  }
  getDBholdings(): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/shHoldings', { headers });
  }
  getDBsh(num): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/shSumData/' + num, { headers });
  }
  getShareHolderSumData(num): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/shareholder/GetShareHolderSumData/' + num, { headers });
  }
  getExpenses(currenttab): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + `/expenses/${currenttab}`, { headers });
  }
  getCCExpenses(currenttab): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + `/expenses/classc/${currenttab}`, { headers });
  }
  getAudit(fundType): Observable<any[]> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.get<any>(this.APIUrl + '/Audit/' + fundType, { headers });
  }
  updateExpense(object) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addExpense', object, { headers });
  }
  addExpense(object) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addExpense/add', object, { headers });
  }
  addClassCExpense(object) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addExpense/addCCExpense', object, { headers });
  }
  updateClassCExpense(object) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addExpense/updateCCExpense', object, { headers });
  }
  addBond(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/addBond', body, { headers });
  }
  addShareholder(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/ShareHolder/addShareholder', body, { headers });
  }
  redemption(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Redemptions', body, { headers });
  }
  UpdateAccountNumber(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Shareholder/UpdateAccountNumber', body, { headers });
  }
  ForeclosureBond(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/AddBond/ForeclosureBond', body, { headers });
  }
  FetchBondDetail(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/AddBond/FetchBondDetail', body, { headers });
  }
  UpdateBond(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/AddBond/UpdateBond', body, { headers });
  }
  RemoveAccountNumber(id) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Shareholder/Remove/' + id, { headers });
  }
  execNAV(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/ExecuteNAV', body, { headers });
  }
  NAVbutton(body) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/NAVbutton', body, { headers });
  }
  /*downloadBSheet() {
  const currentUser = this.authenticationService.currentUserValue;
  const headers = new Headers({ 'Content-Type': 'application/json', 'token': currentUser.token });
  const options = new RequestOptions({ responseType: ResponseContentType.Blob });
  const date = { "update": formatDate(new Date(), 'yyyy-MM-dd', 'en') };
  const op = { headers,date, options };
  //return this.http.get<any>(this.APIUrl + '/balancesheet'*//*, { headers }*//*);
  //return this.httpp.get(this.APIUrl + '/pandlreport', options);
  return this.httpp.get(this.APIUrl + '/api/reporting/bonds', op);
}*/
  downloadBondReport(date, fundType) {
    const headers = new Headers({ 'sdate': date, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.token });
    return this.httpp.get(this.APIUrl + '/reporting/bonds/' + fundType, { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadPandLReport(date, fundType) {
    const headers = new Headers({ 'fdate': date.fromDate, 'tdate': date.toDate, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.token });
    return this.httpp.get(this.APIUrl + '/reporting/profitandloss/' + fundType, { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadYTDReport(date, fundType) {
    const headers = new Headers({ 'fdate': date.fromDate, 'tdate': date.toDate, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.token });
    return this.httpp.get(this.APIUrl + '/reporting/profitandloss/' + fundType, { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadShareholders(date, fundType) {
    const headers = new Headers({ 'tdate': date, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.token });
    return this.httpp.get(this.APIUrl + '/reporting/shareholders/' + fundType, { responseType: ResponseContentType.Blob, headers: headers });
  }
  downloadBalanceSheet(date, fundType) {
    const headers = new Headers({ 'sdate': date, 'Authorization': 'Bearer ' + this.authenticationService.currentUserValue.token });
    return this.httpp.get(this.APIUrl + '/reporting/balancesheet/' + fundType, { responseType: ResponseContentType.Blob, headers: headers });
  }
  /*downloadBonds() {
      const currentUser = this.authenticationService.currentUserValue;
      const headers = { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'*//*, 'token': currentUser.token*//* };
const options = new RequestOptions({ responseType: ResponseContentType.Blob });
return this.httpp.get(this.APIUrl + '/api/reporting/bonds', options);
}*/

  saveCategory(data) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<any>(this.APIUrl + '/Category/Save', data, { headers });
  }
}
