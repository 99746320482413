<head>
  <style>
    .card {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      border-radius: 5px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 400px;
      padding: 15px;
      position: absolute;
      top: 25%;
      left: 40%;

    }

    .logoDiv {
      width: 100%;
      position: relative;
    }

    .logo {
      display: block;
      margin-left: auto; 
      margin-right: auto;
      max-height: 100%
    }
    </style>
</head>

<div class="card">
  <div class="logoDiv">
    <img class="logo" src="/assets/hawksford-logo.png">
  </div>
<h2>Login</h2>

<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label for="username">Username</label>
    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
      <div *ngIf="f.username.errors.required">Username is required</div>
    </div>
  </div>
  <div class="form-group">
    <label for="password">Password</label>
    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">Password is required</div>
    </div>
    <div >
      <div *ngIf="incorrect" style="color:#dc3545; font-size:80%; margin-top: .25rem; width: 100%;">Incorrect Username or Password</div>
    </div>
  </div>
  <div class="form-group">
    <button [disabled]="loading" class="btn btn-primary">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Login
    </button>
  </div>
</form>
</div>
