import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

import { BrowserModule } from '@angular/platform-browser'

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['/node_modules/bulma/css/bulma.css']
})
export class ProfitLossComponent implements OnInit {

  constructor(private service: ApiService) { }
  PLData: any = [];
  isLoader: boolean = true;

  ngOnInit(): void {
    this.refreshPLData();
  }

  refreshPLData() {
    this.service.getPLData().subscribe(data => {
      this.isLoader = false
      this.PLData = data;

    });
  }

}
