import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { User } from '../_models/user';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  baseUrl = environment.baseUrl + '/api';
  jwtHelper = new JwtHelperService();


  constructor(private http: HttpClient, private router: Router, private idle: Idle) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    const body = new HttpParams()
      .set('username', username).set('password', password);

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };


    return this.http.post<any>(this.baseUrl +'/login', { 'username': username, 'password': password }, options)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        // if (user && user.token) {
        //     // store user details and jwt token in local storage to keep user logged in between page refreshes
        //     localStorage.setItem('currentUser', JSON.stringify(user));
        //     this.currentUserSubject.next(user);
        // }
        if (user.successful && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.idle.watch();
          user.username = username;
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user ou
    this.http.post<any>(this.baseUrl + '/logout', { 'token': this.currentUserValue.token, 'expiry': this.currentUserValue.expiry }, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }).subscribe();
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
    this.idle.stop();
  }

  loggedIn() {
    const token = JSON.parse(sessionStorage.getItem('currentUser'));
    if (token) {
      return !this.jwtHelper.isTokenExpired(token.token);
    }
    return false;
  }

}
