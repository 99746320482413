import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AlertService } from '../_services/alert.service';
import { AuthenticationService } from '../_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { UserRedemption } from '../_models/userRedemption';
import Chart from 'chart.js';
import { utils } from 'protractor';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shareholder',
  templateUrl: './shareholder.component.html',
  styleUrls: ['../../../node_modules/bulma/css/bulma.css', './shareholder.component.css']
})
export class ShareholderComponent implements OnInit {
  // userRedemption: UserRedemption;
  SharesAddForm: FormGroup;
  SharesForm: FormGroup;
  submitted = false;
  showModal = false;
  addSubmitted = false;
  returnUrl: string;
  loading: boolean;
  uploading: boolean;
  shareholder: string;
  public canvas: any;
  public ctx;
  public myLineChart;
  shareholdersLineOptions: any;
  public remAction: boolean = true;
  public subAction: boolean = false;

  constructor(private service: ApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }

  numData: any = [];
  SHData: any = [];
  navData: any = [];
  sequentialNum: any = [];
  expenseCap: any = [18, 28];
  actionChoice: any = ['Redeem', 'Invest'];
  ispopUpShow: boolean = false
  snum: string;
  snumUpdate: string;
  enum: string;
  updatep: string;
  updatean: string;
  CurrentTab: number = 1;
  accountNumber = '';
  UpdateAcountNum = '';
  isCurrentAction = '';
  isLoader: boolean = true;

  ngOnInit(): void {
    this.shareholder = 'all';
    this.refreshSHData();
    this.GetSequentialNumbers();

    // Form1
    this.SharesForm = this.formBuilder.group({
      action: ['', Validators.required],
      date: ['', Validators.required],
      sNum: ['', Validators.required],
      aNum: ['', Validators.required],
      navParValue: ['', Validators.required],
      navShareValue: ['', Validators.required],
      amount: ['', Validators.required]
    });

    this.SharesForm.get('navParValue').valueChanges.subscribe(first => {
      this.updateTotal();
    });

    this.SharesForm.get('navShareValue').valueChanges.subscribe(second => {
      this.updateTotal();
    });

    // Form2
    this.SharesAddForm = this.formBuilder.group({
      upDate: ['', Validators.required],
      numOne: ['', Validators.required],
      numTwo: ['', Validators.required],
      expenseCap: ['', Validators.required],
      navParValue: ['', Validators.required],
      navShareValue: ['', Validators.required]
    });

    //linegraph
    this.shareholdersLineOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(0,242,195,0.2)',//'rgba(245,127,23,0.1)',//'rgba(29,140,248,0.0)',
            zeroLineColor: "transparent",
          },
          ticks: {
            /*suggestedMin: 50,
            suggestedMax: 125,*/
            /*userCallback: function (label, index, labels) {
              //var lab = (label / 1000000).toString().concat("M");
              console.log(label);
              console.log(index);
              console.log(labels);
              return label;
            },*/
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }],

        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            display: false,
            drawBorder: false,
            color: 'rgba(0,242,195,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }]
      }
    };
  }

  activeBtn(value: number) {
    this.isLoader = true;
    this.CurrentTab = value;
    this.refreshSHData()
  }


  selectionModel = '';
  GetSequentialNumbers() {
    this.service.getSequentialNumbers().subscribe((data: any) => {
      this.isLoader = false
      this.sequentialNum = data.data
    });
  }

  EditonClick(accountNumber: any, id: any) {
    Swal.fire({
      title: 'Update Shareholder Account Number',
      input: 'text',
      inputValue: accountNumber, // Set the default value here
      inputLabel: 'Account Number',
      showCancelButton: true,
      confirmButtonColor: "#00d1b2",
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.UpdateAccountNumber({
          id: id,
          accountNumber: result.value
        }).pipe().subscribe(response => {
          Swal.fire({
            title: "Account Number Update Successfully!",
            icon: "success",
            confirmButtonColor: "#00d1b2",
          }).then((result) => {
            this.refreshSHData();
            this.GetSequentialNumbers()
          });
        }, error => {
          Swal.fire({
            title: "Error",
            text: error,
            icon: "error",
            confirmButtonColor: "#00d1b2",
          });
        }
        );
      }
    });
  }


  handleChange(event) {
    const filterdata = this.sequentialNum.filter((item) => {
      return item.sequentialNumber === event.target.value
    })
    this.accountNumber = filterdata[0].accountNumber
  }

  handleUpChange(event) {
    const filterdata = this.sequentialNum.filter((item) => {
      return item.sequentialNumber === event.target.value
    })
    this.UpdateAcountNum = filterdata[0].accountNumber
  }

  DeletCLick(id: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.RemoveAccountNumber(id).pipe().subscribe(response => {
          Swal.fire({
            title: "Deleted!",
            text: "Shareholder successfully deleted",
            icon: "success"
          }).then((result) => {
            this.refreshSHData();
          });
          //  this.refreshSHData();
        }, error => {
          console.log(error)
        }
        );
      }
    });
  }

  ClickedOut(event) {
    if (event.target.className === "main-update") {
      this.ispopUpShow = false;
    }
  }

  closepopup() {
    this.ispopUpShow = false;
  }

  refreshSHData() {
    this.service.getShareHolderSumData(this.CurrentTab).subscribe(sdata => {
      this.isLoader = false
      this.canvas = document.getElementById("navChart");
      this.ctx = this.canvas.getContext("2d");

      const data = {
        labels: sdata.data.label,
        datasets: [
          //   {
          //   label: 'Shareholders',
          //   data: sdata.data.shareholders,
          //   borderWidth: 1,
          //   backgroundColor: 'rgba(255, 99, 132, 0.2)'
          // },
          {
            label: 'Investment',
            data: sdata.data.investments,
            borderWidth: 1,
            backgroundColor: 'rgb(255, 99, 132)'
          },
          {
            label: 'Redemption',
            data: sdata.data.redemptions,
            borderWidth: 1,
            backgroundColor: 'rgb(201, 203, 207)'
          },
        ]
      };

      if (this.myLineChart) this.myLineChart.destroy();

      this.myLineChart = new Chart(this.ctx, {
        type: 'bar',
        data: data,
        options: {
          // scales: {
          //   y: {
          //     beginAtZero: true
          //   }
          // }
          tooltips: {
            callbacks: {
              label: function (t, d) {
                var xLabel = d.datasets[t.datasetIndex].label;
                var yLabel = t.yLabel >= 1000 ? '$' + t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$' + t.yLabel;
                return xLabel + ': ' + yLabel;
              }
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return '$' + value;
                  }
                }
              }
            }]
          }
        },
      });
      this.myLineChart.update();
    });

    this.getShareholder()

    this.service.getShareholderNums().subscribe(data => {
      this.numData = data;
    });
  }

  getShareholder() {
    this.service.getShareholderList().subscribe(data => {
      this.SHData = data.data;
    });
  }

  get f() { return this.SharesForm.controls; }
  get share() { return this.SharesAddForm.controls; }

  onAddSubmit() {
    this.addSubmitted = true;

    // stop here if form is invalid
    if (this.SharesAddForm.invalid) {
      return;
    }

    var addSHolder = window.confirm('Are you sure you want to add the following ' + Number(this.share.expenseCap.value) + 'bps Shareholder ' + this.share.numOne.value + ' affective from ' + this.share.upDate.value + '?\n' +
      'Shares: ' + this.share.navParValue.value.toFixed(2) +
      '\nShare Premium: ' + this.share.navShareValue.value.toFixed(2));

    if (addSHolder) {
      this.loading = true;

      this.service.addShareholder(
        {
          'upDate': new Date(this.share.upDate.value).toISOString(),
          'numOne': String(this.share.numOne.value).toUpperCase(),
          'numTwo': String(this.share.numTwo.value).toUpperCase(),
          'expenseCap': Number(this.share.expenseCap.value),
          'newParValue': this.share.navParValue.value,
          'newShareValue': this.share.navShareValue.value,
          'fundType': this.CurrentTab,
        })
        .pipe().subscribe(reply => {
          this.refreshSHData();
          this.SharesAddForm.reset();
          this.snum = undefined;
          this.enum = undefined;
          this.accountNumber = undefined;
          this.addSubmitted = false;
          Object.keys(this.SharesAddForm.controls).forEach(key => {
            this.SharesAddForm.controls[key].markAsPristine();
            this.SharesAddForm.controls[key].markAsUntouched();
          });
          console.log('Added Successfully');
          window.alert(reply.message);
          this.loading = false;
        }, error => {
          console.log(error);
          window.alert(error);
          this.loading = false;
        }
        );
    }
  }

  actionChange(event) {
    this.isCurrentAction = event.target.value
  }

  updateTotal() {
    const navShareValue = parseFloat(this.SharesForm.get('navShareValue').value) || 0;
    const navParValue = parseFloat(this.SharesForm.get('navParValue').value) || 0;
    const total = navShareValue * navParValue;
    this.SharesForm.get('amount').setValue(total);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.SharesForm.invalid) {
      return;
    }
    var updatePos = window.confirm('Are you sure you want to ' + this.f.action.value + ' $' + this.f.amount.value.toFixed(2) + ' for Shareholder ' + this.f.sNum.value + ' affective from ' + this.f.date.value + '?');
    if (updatePos) {
      this.uploading = true;

      if (this.f.action.value == 'Invest') {
        var direction = 1;
      } else {
        var direction = -1;
      }

      this.service.redemption({
        'accountNumber': String(this.f.aNum.value).toUpperCase(),
        'date': new Date(this.f.date.value).toISOString(),
        'amount': Number(this.f.navParValue.value * this.f.navShareValue.value) * direction,
        'newparvalue': this.f.navParValue.value,
        'newsharevalue': this.f.navShareValue.value,
        'fundType': this.CurrentTab,
      })
        .pipe().subscribe(reply => {
          this.refreshSHData();
          this.SharesForm.reset()
          this.updatep = undefined;
          this.updatean = undefined;
          this.snumUpdate = undefined;
          this.UpdateAcountNum = undefined;
          this.isCurrentAction = '';
          this.submitted = false;
          Object.keys(this.SharesForm.controls).forEach(key => {
            this.SharesForm.controls[key].markAsPristine();
            this.SharesForm.controls[key].markAsUntouched();
          });
          console.log('Redeemed Successfully');
          window.alert(reply.message);
          this.uploading = false;
        }, error => {
          console.log(error);
          window.alert('Failed to update Shareholder Position: ' + error);
          this.uploading = false;
        }
        );
    }

  }

  public filter(event: any) {
    this.shareholder = event.target.value.toString();
    this.refreshSHData();
    //if (event.target.value.toString() == 'all') {
    //  this.myLineChart.data.datasets[0].data = this.shDataset;
    //  this.myLineChart.update();
    //} else {
    //  this.myLineChart.data.datasets[0].data = this.sDataset;
    //  this.myLineChart.update();
    //}
  }


}
