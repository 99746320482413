<!--<div style="height:100px; width:90% ">
    <canvas id="myChart"></canvas>
  </div>-->
<!--<content>
  <div style="height:100px; width:45% ">
    <canvas id="pieChart"></canvas>
  </div>
</content>
-->
<div class="tab">
  <ul class="main_tab">
    <li (click)="activeBtn(1)" [class]="CurrentTab === 1 ? 'active' : ''">Old Mutual US Dollar Money Market Fund</li>
    <li (click)="activeBtn(2)" [class]="CurrentTab === 2 ? 'active' : ''">Old Mutual US Dollar Duration Fund</li>
  </ul>
</div>

<div class=" content">
  <div class=" row">
    <div class=" col-12">
      <div class=" card card-chart">
        <div class=" card-header">
          <div class=" row">
            <div class=" col-sm-6 text-left">
              <h5 class=" card-category">OLD MUTUAL US DOLLAR MONEY MARKET FUND</h5>
              <h2 class=" card-title">Performance</h2>
            </div>
            <div class=" col-sm-6">
              <div class=" btn-group btn-group-toggle float-right" data-toggle="buttons">
                <label class=" btn btn-sm btn-danger btn-simple"
                  (click)="data=datasets[0];updateOptions();clicked=true;clicked1=false;clicked2=false"
                  [ngClass]="{'active':clicked===true}">
                  <input checked="checked" name="options" type="radio" />

                  <span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Per Day
                  </span>
                  <span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-single-02"> </i>
                  </span>
                </label>
                <label class=" btn btn-sm btn-danger btn-simple"
                  (click)="data=datasets[1];updateOptions();clicked=false;clicked1=true;clicked2=false"
                  [ngClass]="{'active':clicked1===true}">
                  <input class=" d-none d-sm-none" name="options" type="radio" />

                  <span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Maturity
                  </span>
                  <span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-gift-2"> </i>
                  </span>
                </label>
                <label class=" btn btn-sm btn-danger btn-simple"
                  (click)="data=datasets[2];updateOptions();clicked=false;clicked1=false;clicked2=true"
                  [ngClass]="{'active':clicked2===true}">
                  <input class=" d-none" name="options" type="radio" />

                  <span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    YTD
                  </span>
                  <span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-tap-02"> </i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class=" card-body">
          <div class=" chart-area"><canvas id="chartBig1"> </canvas></div>
        </div>
      </div>
    </div>
  </div>
  <div class=" row">
    <div class=" col-lg-4">
      <div class=" card card-chart">
        <div class=" card-header">
          <div class=" row">
            <div class=" col-sm-6 text-left">
              <h5 class=" card-category">Next 5</h5>
              <h3 class=" card-title">
                <i class=" tim-icons icon-bell-55 text-danger-states"> </i> Expiring Bonds
              </h3>
            </div>
          </div>

        </div>
        <div class=" card-body" style="overflow-x:auto;">
          <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr class="is-selected">
                <th>Fund</th>
                <th>Accrued Days</th>
                <th>Accrued Int Per Day (USD)</th>
                <th>Days Remaining</th>
              </tr>
            </thead>
            <tfoot>
              <tr class="is-selected">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
            <tbody>
              <tr *ngFor="let dataItem of plData; let i = index">
                <td>{{dataItem.fundID}}</td>
                <td>{{dataItem.accruedDays}}</td>
                <td>{{dataItem.accIntPerDay.toFixed(2)}}</td>
                <td>{{dataItem.remDays}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class=" col-lg-4">
      <div class=" card card-chart">
        <div class=" card-header">
          <h5 class=" card-category">{{navDate}}</h5>
          <h3 class=" card-title">
            <i class=" tim-icons icon-delivery-fast text-info"> </i>Best Active Performing Bonds
          </h3>
        </div>
        <div class=" card-body">
          <div class=" chart-area" style="min-height:360px"><canvas id="CountryChart"> </canvas></div>
        </div>
      </div>
    </div>
    <div class=" col-lg-4">
      <div class=" card card-chart">
        <div class=" card-header">
          <h5 class=" card-category">5 Recently</h5>
          <h3 class=" card-title">
            <i class=" tim-icons icon-send text-success"> </i>Added Bonds
          </h3>
        </div>
        <div class=" card-body" style="overflow-x:auto;">
          <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr class="is-selected">
                <th>Contract</th>
                <th>Amount (USD)</th>
                <th>Rate</th>
                <th>Start</th>
                <th>Maturity</th>
                <!--<th>Date Added</th>-->
              </tr>
            </thead>
            <tfoot>
              <tr class="is-selected">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <!--<th></th>-->
              </tr>
            </tfoot>
            <tbody>
              <tr *ngFor="let dataItem of bondData; let i = index">
                <td>{{dataItem.contract}}</td>
                <td>{{dataItem.amount.toFixed(2)}}</td>
                <td>{{dataItem.rate.slice(0, 4)}}{{dataItem.rate.slice(6, 7)}}</td>
                <td>{{dataItem.startDate.toString().slice(2, 10)}}</td>
                <td>{{dataItem.maturityDate.toString().slice(2, 10)}}</td>
                <!--<td>{{dataItem.upDate.toString().slice(2, 10)}}</td>-->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class=" row">
    <div class=" col-12">
      <div class=" card card-chart">
        <div class=" card-header">
          <div class=" row">
            <div class=" col-sm-6 text-left">
              <h5 class=" card-category">Shareholders</h5>
              <h2 class=" card-title">Profit Allocation</h2>
            </div>
            <div class=" col-sm-6">
              <div class=" btn-group btn-group-toggle float-right" data-toggle="buttons">
                <label id="profusd" class=" btn btn-sm btn-danger btn-simple"
                  style="background-color:#26A69A; border-color:#00d6b4;"
                  (click)="profAlldata=profdatasets[0];profclicked=true;profclicked1=false;profclicked2=false;updateProfOpt();"
                  [ngClass]="{'active':profclicked===true}">
                  <input checked="checked" name="options" type="radio" />

                  <span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    USD
                  </span>
                  <span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-single-02"> </i>
                  </span>
                </label>
                <label id="profperc" class=" btn btn-sm btn-danger btn-simple"
                  style="background-color:#00d6b4; border-color:#00d6b4;"
                  (click)="profAlldata=profdatasets[1];profclicked=false;profclicked1=true;profclicked2=false;updateProfOpt();"
                  [ngClass]="{'active':profclicked1===true}">
                  <input class=" d-none d-sm-none" name="options" type="radio" />

                  <span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    %
                  </span>
                  <span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-gift-2"> </i>
                  </span>
                </label>
                <label id="profmov" class=" btn btn-sm btn-danger btn-simple"
                  style="background-color:#00d6b4; border-color:#00d6b4;"
                  (click)="profAlldata=profdatasets[2];profclicked=false;profclicked1=false;profclicked2=true;updateProfOpt();"
                  [ngClass]="{'active':profclicked2===true}">
                  <input class=" d-none d-sm-none" name="options" type="radio" />

                  <span class=" d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    Moving
                  </span>
                  <span class=" d-block d-sm-none">
                    <i class=" tim-icons icon-gift-2"> </i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class=" card-body">
          <div class=" chart-area"><canvas id="profitChart"> </canvas></div>
        </div>
      </div>
    </div>
  </div>
  <div class=" row">
    <!-- <div class=" col-lg-4">
      <div class=" card card-chart">
        <div class=" card-header">
          <div class=" row">
            <div class=" col-sm-6 text-left">
              <h5 class=" card-category">Shareholders ({{shareholdersnum}})</h5>
              <h3 class=" card-title">
                <i class=" tim-icons icon-bell-55 text-danger-states"> </i> % Holdings
              </h3>
            </div>
          </div>

        </div>
        <div class=" card-body">
          <div class=" chart-area" style="min-height:350px"><canvas id="perHolding"> </canvas></div>
        </div>
      </div>
    </div> -->
    <div class=" col-lg-6">
      <div class=" card card-chart">
        <div class=" card-header">
          <h5 class=" card-category">Latest Shareholder</h5>
          <h3 class=" card-title">
            <i class=" tim-icons icon-delivery-fast text-info"> </i> Redemptions/Subscriptions
          </h3>
        </div>
        <div class=" card-body" style="overflow-x:auto;">
          <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr class="is-selected">
                <th>Date</th>
                <th>Shareholder</th>
                <th>Amount (USD)</th>
              </tr>
            </thead>
            <tfoot>
              <tr class="is-selected">
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
            <tbody>
              <tr *ngFor="let dataItem of redData; let i = index">
                <td>{{dataItem.upDate.toString().slice(0, 10)}}</td>
                <td>{{dataItem.name}}</td>
                <td>{{dataItem.amount.toFixed(2)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class=" col-lg-6">
      <div class=" card card-chart">
        <div class=" card-header">
          <h5 class=" card-category">Accumulated Shareholder's NAV per day</h5>
          <h3 class=" card-title">
            <i class=" tim-icons icon-send text-success"> </i> NAV
          </h3>
        </div>
        <div class=" card-body">
          <div class=" chart-area" style="min-height:350px"><canvas id="chartLineGreen"> </canvas></div>
        </div>
      </div>
    </div> -->
  </div>
  <div class=" row">

  </div>
</div>

<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>