import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import Chart from 'chart.js';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['/node_modules/bulma/css/bulma.css', './expenses.component.css']
})
export class ExpensesComponent implements OnInit {

  categoryForm: FormGroup;
  newExpenseForm: FormGroup;
  ExpenseForm: FormGroup;
  ExpenseCCForm: FormGroup;
  UpdateExpenseCCForm: FormGroup;
  submittednew = false;
  submittedCategory = false;
  submitted = false;
  submittedcc = false;
  submittedNewCC = false;
  returnUrl: string;
  categoryvalue: string;
  editclass: string;
  expensevalue: string;
  loading: boolean;
  pa = false;
  CurrentTab: number = 1;
  myDoughnutChart
  myDoughnutCCChart

  constructor(private service: ApiService,
    private formBuilder: FormBuilder) { }

  expData: any = [];
  expCCData: any = [];
  //expenseDescriptions: any = ['Audit Fee Expense', 'Director 1', 'Director 2', 'FSC License Fee', 'MRA Service Fee (TRC Renewal)', 'ROC', 'Disbursement', 'Bank Charges'];
  newExpenseDescriptions: any = [];
  expenseDescriptions: any = [];
  editDescriptions: any = [];
  expenseCCDescriptions: any = [];
  expenseCCData: any = [];
  Category: any = [];
  isLoader: boolean = true;
  btnCategoryLoader : boolean = false;
  btnAddExpenseLoader : boolean = false;
  btnEditExpenseLoader : boolean = false;
  btnAddExpenseCCLoader : boolean = false;
  btnEditExpenseCCLoader : boolean = false;

  ngOnInit(): void {
    this.refreshExpData();
    this.refreshCCExpData();
    this.getCategory()

    this.categoryForm = this.formBuilder.group({
      id: [null],
      name: ['', Validators.required],
      description: ['']
    });

    this.newExpenseForm = this.formBuilder.group({
      expense: ['', Validators.required],
      date: ['', Validators.required],
      amount: ['', Validators.required],
      days: ['', Validators.required]/*,
      perAnum: ['', Validators.required]*/
    });

    this.ExpenseForm = this.formBuilder.group({
      expenseDescriptions: ['', Validators.required],
      date: ['', Validators.required],
      amount: ['', Validators.required],
      days: ['', Validators.required]
    });

    this.ExpenseCCForm = this.formBuilder.group({
      id: [0],
      description: ['', Validators.required],
      date: ['', Validators.required],
      amount: ['', Validators.required],
      days: ['', Validators.required]
    });

    this.UpdateExpenseCCForm = this.formBuilder.group({
      id: [0, Validators.required],
      date: ['', Validators.required],
      amount: ['', Validators.required],
      days: ['', Validators.required]
    });
  }

  activeBtn(value: number) {
    this.CurrentTab = value;
    this.isLoader = true
    this.refreshExpData();
    this.refreshCCExpData();
  }

  onSubmitCategory() {
    this.submittedCategory = true;

    // stop here if form is invalid
    if (this.categoryForm.invalid) {
      console.log("invalid form");
      return;
    }

    this.loading = true;
    this.btnCategoryLoader = true;

    this.service.saveCategory(this.categoryForm.value).pipe().subscribe(reply => {

      this.getCategory();
      this.btnCategoryLoader = false;
      this.categoryForm.reset();
      Object.keys(this.categoryForm.controls).forEach(key => {
        this.categoryForm.controls[key].setErrors(null);
        this.categoryForm.controls[key].setValue('');
      });
      this.submittedCategory = false;
      window.alert('Added New Category Successfully');
    }, (error: any) => {
      this.btnCategoryLoader = false;
      console.log(error);
      window.alert('Failed to add category: ' + error);
    }
    );
  }

  getCategory() {
    this.service.getCategory().subscribe((data: any) => {
      this.isLoader = false
      this.Category = data.data
    });
  }

  refreshExpData() {
    this.service.getExpenses(this.CurrentTab).subscribe(eData => {
      this.isLoader = false
      this.expData = eData;

      var sumExp = 0;
      var graphData = [];
      var expenseDescriptions = []; // Move declaration here
      var graphColours = [];
      var color = ["Blue", "Red", "Indigo", "Violet", "Green", "Pink", "Magenta", "Yellow", "Lime", "Aqua", "DarkOrange"];
      // var editDescriptions = []; // Move declaration here

      for (var item of this.expData) {
        sumExp += item.expPerDay;
        this.editDescriptions.push(item.description);
        expenseDescriptions.push(item.description);
        graphData.push(item.expPerDay);
        graphColours.push(this.getRandomColor());
      }

      document.getElementById("sumExp").innerHTML = sumExp.toFixed(2).toString();

      if (this.myDoughnutChart) {
        this.myDoughnutChart.destroy();
      }

      var ctx = document.getElementById("pieChart");
      this.myDoughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: expenseDescriptions,
          datasets: [{
            backgroundColor: color,//graphColours,
            data: graphData
          }]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest"
          },
          responsive: true,
          maintainAspectRatio: true
        }
      });

    })
  }

  refreshCCExpData() {
    this.service.getCCExpenses(this.CurrentTab).subscribe(cData => {
      this.isLoader = false
      this.expCCData = cData;

      var sumCCExp = 0;
      var graphData = [];
      //var graphLabels = [];
      var graphColours = [];
      var color = ["Lime", "Aqua", "Yellow", "Blue", "Pink", "Red", "DarkOrange", "Violet", "Indigo"];

      this.expCCData = cData;

      for (var item of this.expCCData) {
        sumCCExp = sumCCExp + item.expPerDay;
        if (item.description.includes('Management') || item.description.includes('Admin') || item.description.includes('Organisation')) {
          var str = new String(' (latest)');
          item.description = item.description.concat(' (latest)');
          this.expenseCCDescriptions.push(item.description.concat(str.toString()));
        } else {
          this.expenseCCDescriptions.push(item.description);
        }
        graphData.push(item.expPerDay);
        graphColours.push(this.getRandomColor());
      }
      document.getElementById("sumCCExp").innerHTML = sumCCExp.toFixed(2).toString();
      if (this.myDoughnutCCChart) {
        this.myDoughnutCCChart.destroy();
      }
      var ctx = document.getElementById("CCpieChart");
      this.myDoughnutCCChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: this.expenseCCDescriptions,
          datasets: [{
            backgroundColor: color,//graphColours,
            data: graphData
          }]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            backgroundColor: '#f5f5f5',
            titleFontColor: '#333',
            bodyFontColor: '#666',
            bodySpacing: 4,
            xPadding: 12,
            mode: "nearest",
            intersect: 0,
            position: "nearest"
          },
          responsive: true,
          maintainAspectRatio: true
        }
      });

    })
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  get f() { return this.ExpenseForm.controls; }
  get cc() { return this.ExpenseCCForm.controls; }
  get e() { return this.newExpenseForm.controls; }

  changePA() {
    console.log(this.pa)
    if (this.pa) {
      this.pa = false;
    } else {
      this.pa = true;
    }
  }

  onNewSubmit() {
    console.log("on new submit");
    this.submittednew = true;

    // stop here if form is invalid
    if (this.newExpenseForm.invalid) {
      console.log("invalid form");
      return;
    }
    var addExp = window.confirm('Are you sure you want to add the following Expense affective from ' + this.e.date.value + '?\n' +
      'Expense: ' + this.e.expense.value +
      '\nAmount: $' + this.e.amount.value.toFixed(2) +
      '\nDays: ' + this.e.days.value);
    

    if (addExp) {
      this.loading = true;
      this.btnAddExpenseLoader = true;

      this.service.addExpense({
        'description': this.e.expense.value,
        'date': new Date(this.e.date.value).toISOString(),
        'amount': this.e.amount.value,
        'days': this.e.days.value,
        "fundType": this.CurrentTab
      })
        .pipe().subscribe(reply => {

          this.btnAddExpenseLoader = false;
          this.refreshExpData();
          this.newExpenseForm.reset();
          this.categoryvalue = undefined
          this.submittednew = false;
          Object.keys(this.newExpenseForm.controls).forEach(key => {
            // this.newExpenseForm.controls[key].setErrors(null);
            this.newExpenseForm.controls[key].markAsPristine();
            this.newExpenseForm.controls[key].markAsUntouched();
          });
          console.log('Added New Expense Successfully');
          window.alert(reply.message);
        }, error => {
          this.btnAddExpenseLoader = false;
          console.log(error);
          window.alert('Failed to add Expense: ' + error);
        }
        );
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.ExpenseForm.invalid) {
      return;
    }
    var updateExp = window.confirm('Are you sure you want to edit ' + this.f.expenseDescriptions.value + ' to the following, affective from ' + this.f.date.value + '?\n' +
      'Amount: $' + this.f.amount.value.toFixed(2) +
      '\nDays: ' + this.f.days.value);

    if (updateExp) {
      this.loading = true;
      this.btnEditExpenseLoader = true;

      this.service.updateExpense({ 
        'description': this.f.expenseDescriptions.value, 
        'date': new Date(this.f.date.value).toISOString(), 
        'amount': this.f.amount.value, 
        'days': this.f.days.value,
        "fundType": this.CurrentTab
      })
        .pipe().subscribe(reply => {

          this.btnEditExpenseLoader = false;
          this.refreshExpData();
          this.ExpenseForm.reset();
          this.expensevalue = undefined
          this.submitted = false;
          Object.keys(this.ExpenseForm.controls).forEach(key => {
            // this.ExpenseForm.controls[key].setErrors(null);
            this.ExpenseForm.controls[key].markAsPristine();
            this.ExpenseForm.controls[key].markAsUntouched();
          });
          console.log('Added Successfully');
          window.alert(reply.message);
        }, error => {
          this.btnEditExpenseLoader = false;
          console.log(error);
          window.alert('Failed to update expense: ' + error);
        }
        );
    }

  }

  addNewCC() {
    this.submittedNewCC = true;

    // stop here if form is invalid
    if (this.ExpenseCCForm.invalid) {
      return;
    }

    var addCCExp = window.confirm('Are you sure you want to add ' + this.cc.description.value + ' to the following, affective from ' + this.cc.date.value + '?\n' +
      'Amount: $' + this.cc.amount.value.toFixed(2) +
      '\nDays: ' + this.cc.days.value);

    if (addCCExp) {
      this.loading = true;
      this.btnAddExpenseCCLoader = true;

      const data = {
        "id": this.ExpenseCCForm.value.id,
        "description": this.ExpenseCCForm.value.description,
        "date": this.ExpenseCCForm.value.date,
        "amount": this.ExpenseCCForm.value.amount,
        "days": this.ExpenseCCForm.value.days,
        "fundType": this.CurrentTab
      }

      this.service.addClassCExpense(data).pipe().subscribe(reply => {

        this.btnAddExpenseCCLoader = false;
        this.refreshCCExpData();
        this.ExpenseCCForm.reset();

        Object.keys(this.ExpenseCCForm.controls).forEach(key => {
          // this.ExpenseCCForm.controls[key].setErrors(null);
          this.ExpenseCCForm.controls[key].markAsPristine();
          this.ExpenseCCForm.controls[key].markAsUntouched();
        });

        console.log();
        window.alert('Added Successfully Class C Expense');

        this.submittedNewCC = false;

      }, error => {
        this.btnAddExpenseCCLoader = false;
        console.log(error);
        window.alert('Failed to add Class C Expense: ' + error);
      }
      );
    }

  }

  updateCC() {
    this.submittedcc = true;

    // stop here if form is invalid
    if (this.UpdateExpenseCCForm.invalid) {
      return;
    }
    var updateccExp = window.confirm('Are you sure you want to edit to the following, affective from ' + this.UpdateExpenseCCForm.controls['date'].value + '?\n' +
      'Amount: $' + this.UpdateExpenseCCForm.controls['amount'].value.toFixed(2) +
      '\nDays: ' + this.UpdateExpenseCCForm.controls['days'].value);

    if (updateccExp) {
      this.loading = true;
      this.btnEditExpenseCCLoader = true;

      var body = {
        "id": parseInt(this.UpdateExpenseCCForm.controls['id'].value),
        "date": this.UpdateExpenseCCForm.controls['date'].value,
        "amount": parseFloat(this.UpdateExpenseCCForm.controls['amount'].value.toFixed(2)),
        "days": this.UpdateExpenseCCForm.controls['days'].value
      }

      this.service.updateClassCExpense(body).pipe().subscribe(reply => {

        this.refreshCCExpData();
        this.UpdateExpenseCCForm.reset({ id: 0 });
        this.btnEditExpenseCCLoader = false;

        Object.keys(this.UpdateExpenseCCForm.controls).forEach(key => {
          // this.UpdateExpenseCCForm.controls[key].setErrors(null);
          this.UpdateExpenseCCForm.controls[key].markAsPristine();
          this.UpdateExpenseCCForm.controls[key].markAsUntouched();
        });

        this.submittedcc = false;

        window.alert('Updated Successfully Class C Expense');
      }, error => {
        this.btnEditExpenseCCLoader = false;
        console.log(error);
        window.alert('Failed to edit Class C Expense: ' + error);
      }
      );
    }

  }
}
