<div class="row1">
  <ul class="main_tab">
    <li (click)="activeBtn(1)" [class]="CurrentTab === 1 ? 'active' : ''">Old Mutual US Dollar Money Market Fund</li>
    <li (click)="activeBtn(2)" [class]="CurrentTab === 2 ? 'active' : ''">Old Mutual US Dollar Duration Fund</li>
  </ul>
</div>
<div class="row">
  <div class="column" style="float: left; max-width: 50%">
    <form [formGroup]="SharesAddForm" (ngSubmit)="onAddSubmit()" class="form-inline">
      <div style="
          width: 100%;
          padding-top: 30px;
          padding-bottom: 30px;
          padding-left: 30px;
        ">
        <label class="label">Subscribe
          ({{CurrentTab == 1 ? 'Old Mutual US Dollar Money Market Fund' : 'Old Mutual US Dollar DurationFund'}})</label>
        <div class="field" style="display: flex; flex-flow: row wrap; align-items: normal">
          <div class="form-group">
            <input formControlName="upDate" class="form-control" type="date" placeholder="Date"
              [ngClass]="{ 'is-invalid': addSubmitted && share.upDate.errors }" />
            <div *ngIf="addSubmitted && share.upDate.errors" class="invalid-feedback">
              <div *ngIf="share.upDate.errors.required">Date is required</div>
            </div>
          </div>
          <div class="form-group pl-2 pr-2">
            <select class="form-control" formControlName="numTwo"
              [ngClass]="{ 'is-invalid': addSubmitted && share.numTwo.errors }" (change)="handleChange($event)"
              [(ngModel)]="snum">
              <option [ngValue]="undefined" disabled>Select Sequential Number</option>
              <option *ngFor="let item of sequentialNum" [value]="item.sequentialNumber">
                {{ item.sequentialNumber }}
              </option>
            </select>
            <div *ngIf=" addSubmitted && share.numTwo.errors" class="invalid-feedback">
              <div *ngIf="share.numTwo.errors.required">
                Sequential Number is required
              </div>
            </div>
          </div>
          <div class="form-group pl-2">
            <input formControlName="numOne" class="form-control" type="text" placeholder="Account Number (P)"
              [ngClass]="{ 'is-invalid': addSubmitted && share.numOne.errors }" [ngModel]="accountNumber"
              [readOnly]="true" />
            <div *ngIf="addSubmitted && share.numOne.errors" class="invalid-feedback">
              <div *ngIf="share.numOne.errors.required">
                Account Number is required
              </div>
            </div>
          </div>
          <div class="form-group pl-2 pr-2">
            <select class="form-control" formControlName="expenseCap"
              [ngClass]="{'is-invalid': addSubmitted && share.expenseCap.errors}" [(ngModel)]="enum">
              <option [ngValue]="undefined" disabled>Please select a Expense Cap</option>
              <option *ngFor="let expenseCap of expenseCap">
                {{ expenseCap }}
              </option>
            </select>
            <div *ngIf="addSubmitted && share.expenseCap.errors" class="invalid-feedback">
              <div *ngIf="share.expenseCap.errors.required">
                Expense Cap is required
              </div>
            </div>
          </div>
          <!--<button class="btn btn-success">Add</button>-->
        </div>
        <div class="field" style="flex-flow: row wrap; align-items: normal">
          <div class="form-group">
            <input formControlName="navParValue" class="form-control" type="number" step="0.01"
              placeholder="Number of Shares" [ngClass]="{'is-invalid': addSubmitted && share.navParValue.errors }" />
            <div *ngIf="addSubmitted && share.navParValue.errors" class="invalid-feedback">
              <div *ngIf="share.navParValue.errors.required">
                New PAR Value is required
              </div>
            </div>
          </div>
          <div class="form-group pl-2 pr-2">
            <input formControlName="navShareValue" class="form-control" type="number" step="0.01"
              placeholder="Share Premium" [ngClass]="{'is-invalid': addSubmitted && share.navShareValue.errors}" />
            <div *ngIf="addSubmitted && share.navShareValue.errors" class="invalid-feedback">
              <div *ngIf="share.navShareValue.errors.required">
                New Share Value is required
              </div>
            </div>
          </div>
          <button class="btn btn-success">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Add
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="column" style="float: right; max-width: 50%">
    <!--Form 2-->
    <form [formGroup]="SharesForm" (ngSubmit)="onSubmit()" class="form-inline">
      <!--#r="ngForm"-->
      <div style="width: 100%; padding-top: 30px; padding-bottom: 30px;padding-left: 50px;">
        <label class="label">Update Shareholder Position ({{CurrentTab == 1 ? 'Money Market' :
          'DirectDuration'}})</label>
        <div class="field" style="flex-flow: row wrap; align-items: normal">
          <div class="form-group">
            <select class="form-control" formControlName="action"
              [ngClass]="{ 'is-invalid': submitted && f.action.errors }" [(ngModel)]="updatep"
              (change)="actionChange($event)">
              <option [ngValue]="undefined" disabled>Please select an Action</option>
              <option *ngFor="let action of actionChoice">{{ action }}</option>
            </select>
            <div *ngIf="submitted && f.action.errors" class="invalid-feedback">
              <div *ngIf="f.action.errors.required">Action is required</div>
            </div>
          </div>
          <div class="form-group pl-2">
            <input formControlName="date" class="form-control" type="date" placeholder="Date"
              [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
            <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
              <div *ngIf="f.date.errors.required">Date is required</div>
            </div>
          </div>
          <div class="form-group pl-2 pr-2">
            <select class="form-control" formControlName="sNum" [ngClass]="{ 'is-invalid': submitted && f.sNum.errors }"
              (change)="handleUpChange($event)" [(ngModel)]="snumUpdate">
              <option [ngValue]="undefined" disabled>Select Sequential Number</option>
              <option *ngFor="let item of sequentialNum" [value]="item.sequentialNumber">
                {{ item.sequentialNumber }}
              </option>
            </select>
            <div *ngIf=" submitted && f.sNum.errors" class="invalid-feedback">
              <div *ngIf="f.sNum.errors.required">
                Sequential Number is required
              </div>
            </div>
          </div>
          <div class="form-group pl-2">
            <input formControlName="aNum" class="form-control" type="text" placeholder="Account Number (P)"
              [ngClass]="{ 'is-invalid': submitted && f.aNum.errors }" [ngModel]="UpdateAcountNum" [readOnly]="true" />
            <div *ngIf="submitted && f.aNum.errors" class="invalid-feedback">
              <div *ngIf="f.aNum.errors.required">
                Account Number is required
              </div>
            </div>
          </div>
          <div class="field" style="flex-flow: row wrap; align-items: normal; margin-top: 20px;">
            <div class="form-group">
              <input formControlName="navParValue" class="form-control" type="number" step="0.01"
                placeholder="Number of Shares" [ngClass]="{
                'is-invalid': submitted && f.navParValue.errors
              }" />
              <div *ngIf="submitted && f.navParValue.errors" class="invalid-feedback">
                <div *ngIf="f.navParValue.errors.required">
                  New PAR Value is required
                </div>
              </div>
            </div>
            <div class="form-group pl-2 pr-2">
              <input formControlName="navShareValue" class="form-control" type="number" step="0.01"
                placeholder="Share Premium" [ngClass]="{
                'is-invalid': submitted && f.navShareValue.errors
              }" />
              <div *ngIf="submitted && f.navShareValue.errors" class="invalid-feedback">
                <div *ngIf="f.navShareValue.errors.required">
                  New Share Value is required
                </div>
              </div>
            </div>
            <div class="form-group pl-2 pr-2" *ngIf="isCurrentAction === 'Redeem'">
              <input formControlName="amount" class="form-control" type="number" step="0.01" placeholder="Amount(USD)"
                [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" readonly />
              <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                <div *ngIf="f.amount.errors.required">Amount is required</div>
              </div>
            </div>
            <button class="btn btn-success">
              <span *ngIf="uploading" class="spinner-border spinner-border-sm mr-1"></span>
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!--<div class="buttons are-small" style="padding-top: 10px">
  <button class="btn btn-success">Download Shareholdings .xlsx</button>
</div>-->
<div class="row" id="line">
  <div class="col-12">
    <div class="card card-chart">
      <!--<div class=" card-header">
        <h2 class=" card-title">{{shareholder}}</h2>
      </div>-->
      <div class="card-body">
        <div class="chart-area">
          <canvas id="navChart" height="50"> </canvas>
        </div>
      </div>
    </div>
  </div>
</div>

<table class="table is-bordered is-striped is-narrow is-hoverable" id="tab">
  <thead>
    <tr class="is-selected">
      <th></th>
      <th>Date</th>
      <th>Account Number</th>
      <th>Sequential Number</th>
      <th>Name</th>
      <th>Email</th>
      <th>Date of birth</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let dataItem of SHData; let i = index">
      <th>{{ i + 1 }}</th>
      <td>{{ dataItem.activeDate }}</td>
      <td>{{ dataItem.num1 }}</td>
      <td>{{ dataItem.num2 }}</td>
      <td>{{ dataItem.name }}</td>
      <td>{{ dataItem.email }}</td>
      <td>{{ dataItem.dob }}</td>
      <td>
        <span class="fa fa-edit" (click)="EditonClick(dataItem.num1,dataItem.id)"
          style="cursor: pointer;"></span>&nbsp;&nbsp;
        <span class="fa fa-trash trash-icon" (click)="DeletCLick(dataItem.id)"></span>
      </td>
    </tr>
  </tbody>
</table>

<div class="spinner-overlay" *ngIf="isLoader">
  <div class="spinner"></div>
</div>