
<div class="container">
  <!--style="flex-direction:column; flex:2"-->
  <!--<div style="height:100px; width:90% ">
    <canvas id="myChart"></canvas>
  </div>-->
  <!--<div style="flex:1">
    <table class="table table-striped">
      <tbody>
        <tr *ngFor="let dataItem of NavData">
          <td>{{dataItem.fundID}}</td>
          <td>${{dataItem.accYTD.toFixed(2)}}</td>
        </tr>
      </tbody>
    </table>
  </div>-->
  <div style="height:100px; width:90% ">
    <canvas id="pieChart"></canvas>
  </div>
  <!--<div style="height:100px; width:90% ">
    <canvas id="pieChart18"></canvas>
  </div>-->
</div>



