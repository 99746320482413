import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import Chart from 'chart.js';

import { BrowserModule } from '@angular/platform-browser'

@Component({
  selector: 'app-show-data',
  templateUrl: './show-data.component.html',
  styleUrls: ['./show-data.component.css']
})
export class ShowDataComponent implements OnInit {

  constructor(private service: ApiService) { }

  NavData: any = [];
  shareData: any = [];

  ngOnInit(): void {
    this.refreshNAVData();
    //this.refreshShareHolderData();
  }
  getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

  refreshNAVData() {
    this.service.getNavData('all').subscribe(data => {
      this.NavData = data;

      var ctx = document.getElementById("myChart");
      var graphData = [];
      var graphLabels = [];
      var graphColours = [];
     /* for (var item of this.NavData) {
        graphLabels.push(item.fundID)
        graphData.push(item.accIntPerDay);
        graphColours.push(this.getRandomColor())
      }

      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: graphLabels,//['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
            label: 'Accrued Interest/day',
            data: graphData,//data: [12, 19, 3, 5, 2, 3],
            backgroundColor: graphColours,
            //borderColor: graphColours,
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });*/
    });
    this.service.getShareholder('all').subscribe(sdata => {
      this.shareData = sdata;
      
      var graphData = [];/*
      var graphData18 = [];
      var graphData28 = [];*/

      var graphLabels = [];/*
      var graphLabels18 = [];
      var graphLabels28 = [];*/

      var graphColours = [];/*
      var graphColours18 = [];*/

      for (var item of this.shareData) {
        graphLabels.push(item.num);
        graphData.push(item.percHolding);
        graphColours.push(this.getRandomColor());

        /*if (item.num.includes('P1')) {
          graphLabels18.push(item.num);
          graphData18.push(item.percBpsHolding);
          graphColours18.push(this.getRandomColor());
        }*/
          
      }
      //console.log(graphData);
      var ctx = document.getElementById("pieChart");
      var pieChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: graphLabels,
          datasets: [{
            backgroundColor: graphColours,
            data: graphData
          }]
        }
      });
      /*var ctx18 = document.getElementById("pieChart18");
      var pieChart2 = new Chart(ctx18, {
        type: 'pie',
        data: {
          labels: graphLabels18,
          datasets: [{
            backgroundColor: graphColours18,
            data: graphData18
          }]
        }
      });*/
    });
  }

  
}
