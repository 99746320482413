import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import Chart from 'chart.js';

import { BrowserModule } from '@angular/platform-browser'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bond-acruel',
  templateUrl: './bond-acruel.component.html',
  styleUrls: ['../../../node_modules/bulma/css/bulma.css', './bond-acruel.component.css']
})
export class BondAcruelComponent implements OnInit {
  BondForm: FormGroup;
  submitted = false;
  returnUrl: string;
  securityvalue: string;
  loading: boolean;
  contract: string;
  public canvas: any;
  public ctx;
  public myLineChart;
  LineOptions: any;
  CurrentTab: number = 1;
  isLoader: boolean = true;

  constructor(private service: ApiService,
    private formBuilder: FormBuilder) { }

  contractData: any = [];
  AccData: any = [];
  bAccData: any = [];
  security: any = ['Firstrand Bank Limited, London', 'Nedbank London'];
  fund: any = 'Old Mutual US Dollar Money Market';
  type: any = 'Commercial Paper';
  currency: any = 'USD';

  ngOnInit(): void {
    this.contract = 'all';
    this.refreshAccData();
    this.BondForm = this.formBuilder.group({
      update: ['', Validators.required],
      contract: ['', Validators.required],
      security: ['', Validators.required],
      amount: ['', Validators.required],
      rate: ['', Validators.required],
      startDate: ['', Validators.required],
      resetDate: ['', Validators.required],
      maturitydate: ['', Validators.required]
    });
    this.LineOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: 'rgba(0,242,195,0.2)',
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }],
        xAxes: [{
          barPercentage: 1.6,
          gridLines: {
            display: false,
            drawBorder: false,
            color: 'rgba(0,242,195,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            userCallback: function (label, index, labels) {
              return label;
            },
            display: false,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }]
      }
    };
  }

  ForeclosureBond() {
    Swal.fire({
      // title: 'Foreclosure Bond',
      input: 'text',
      inputLabel: 'Foreclosure Bond',
      inputPlaceholder: 'Enter Contract Id',
      confirmButtonColor: "#00d1b2",
      confirmButtonText: 'Submit',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Are you sure you want to foreclose this Bond?',
          text: 'Closing this bond now means it will no longer record interest on the next day. only confirm if you are sure about this. ',
          icon: 'warning',
          customClass: "alert-icon",
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          confirmButtonColor: "#00d1b2",
          cancelButtonText: 'Cancel'
        }).then((confirmResult) => {
          if (confirmResult.isConfirmed) {
            this.service.ForeclosureBond({
              contractId: result.value,
              FundType: this.CurrentTab
            }).subscribe(
              (response) => {
                Swal.fire({
                  title: 'Bond has been close and it will no longer record any interest.',
                  icon: 'success'
                });
              },
              (error) => {
                Swal.fire({
                  title: 'Error',
                  text: error,
                  icon: 'error',
                  confirmButtonColor: "#00d1b2",
                });
              }
            );
          }
        });
      }
    });
  }

  UpdateBond() {
    Swal.fire({
      input: 'text',
      inputLabel: 'Update Bond',
      inputPlaceholder: 'Enter Contract Id',
      confirmButtonColor: "#00d1b2",
      confirmButtonText: 'Fetch',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.FetchBondDetail({
          contractId: result.value,
          fundType: this.CurrentTab
        }).subscribe(
          (response) => {
            Swal.fire({
              title: 'Update Bond',
              html:
                `<div><label class="w-100 text-left">Rate :</label>
                <input id="swal-input1" class="swal2-input m-0 w-100" placeholder="Rate" value="${response.data.rate}" ></div>` +
                `<div><label class="w-100 text-left">Amount :</label>
                <input id="swal-input2" class="swal2-input m-0 w-100" placeholder="Amount" value="${response.data.amount}"></div>`,
              showCancelButton: true,
              confirmButtonColor: "#00d1b2",
              customClass: "update-bond-field",
              preConfirm: () => {
                const rate = (<HTMLInputElement>document.getElementById('swal-input1')).value;
                const amount = (<HTMLInputElement>document.getElementById('swal-input2')).value;

                if (!rate || !amount) {
                  Swal.showValidationMessage(`Please enter rate and amount.`)
                }
                return [rate, amount];
              },
              confirmButtonText: 'Submit',
            }).then((result) => {
              if (result.isConfirmed) {
                const [rate, amount] = result.value;
                this.service.UpdateBond({
                  contractId: response.data.contractId,
                  amount: Number(amount),
                  rate: rate + "%",
                  fundType: this.CurrentTab
                }).pipe().subscribe(response => {
                  Swal.fire({
                    title: "Bond Update Successfully!",
                    icon: "success",
                    confirmButtonColor: "#00d1b2",
                  })
                }, error => {
                  Swal.fire({
                    title: "Error",
                    text: error,
                    icon: "error",
                    confirmButtonColor: "#00d1b2",
                  });
                });
              }
            });
          },
          (error) => {
            Swal.fire({
              title: 'Error',
              text: error,
              icon: 'error',
              confirmButtonColor: "#00d1b2",
            });
          }
        );
      }
    });
  }

  refreshAccData() {
    this.service.getBondContracts().subscribe(bdata => {
      this.isLoader = false
      this.contractData = bdata;
    });
    this.getNavData()
    this.getNavDataLine()
  }


  activeBtn(value: number) {
    this.CurrentTab = value;
    this.isLoader = true
    this.getNavData()
    this.getNavDataLine()
  }

  getNavData() {
    let data = {
      contract: this.contract,
      currenttab: this.CurrentTab
    }
    this.service.getNavData(data).subscribe(data => {
      this.isLoader = false
      this.AccData = data;
      var sumInt = 0;
      var sumIntPd = 0;
      var sumYTD = 0;
      for (var item of this.AccData) {
        sumInt = sumInt + item.accIntMatured;
        sumIntPd = sumIntPd + item.accIntPerDay;
        sumYTD = sumYTD + item.accYTD;
      }
      document.getElementById("sumInt").innerHTML = sumInt.toFixed(2).toString();
      document.getElementById("sumIntPd").innerHTML = sumIntPd.toFixed(2).toString();
      document.getElementById("sumYTD").innerHTML = sumYTD.toFixed(2).toString();
    });
  }

  getNavDataLine() {
    let data = {
      contract: this.contract,
      currenttab: this.CurrentTab
    }
    this.service.getNavDataLine(data).subscribe(data => {
      this.isLoader = false
      this.bAccData = data;
      var sumInt = 0;
      var sumIntPd = 0;
      var sumYTD = 0;
      var bLabels: any = [];
      var bDataset: any = [];
      for (var item of this.bAccData) {
        bLabels.push(item.navDate.toString().slice(2, 10));
        bDataset.push(item.accYTD.toFixed(2));
        sumInt = sumInt + item.accIntMatured;
        sumIntPd = sumIntPd + item.accIntPerDay;
        sumYTD = sumYTD + item.accYTD;
      }
      // document.getElementById("sumInt").innerHTML = sumInt.toFixed(2).toString();
      // document.getElementById("sumIntPd").innerHTML = sumIntPd.toFixed(2).toString();
      // document.getElementById("sumYTD").innerHTML = sumYTD.toFixed(2).toString();

      this.canvas = document.getElementById("bondChart");
      this.ctx = this.canvas.getContext("2d");

      var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);
      gradientStroke.addColorStop(1, 'rgba(0,242,195,0.2)');
      gradientStroke.addColorStop(0.4, 'rgba(0,242,195,0.0)'); //green colors
      gradientStroke.addColorStop(0, 'rgba(0,242,195,0)');
      var linedata = {
        labels: bLabels,
        datasets: [{
          label: "YTD",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: '#00d6b4',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: '#00d6b4',
          pointBorderColor: 'rgba(245,127,23,0)',
          pointHoverBackgroundColor: '#00d6b4',
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 0,
          data: bDataset,
        }]
      };
      if (this.myLineChart) this.myLineChart.destroy();
      this.myLineChart = new Chart(this.ctx, {
        type: 'line',
        data: linedata,
        options: this.LineOptions
      });
      this.myLineChart.update();
    });
  }

  get f() { return this.BondForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.BondForm.invalid) {
      return;
    }
    var addBond = window.confirm('Are you sure you want to add the following ' + this.f.security.value + ' Commercial Paper, ' + this.f.contract.value + ' affective from ' + this.f.update.value + '?\n' +
      'Amount: $' + this.f.amount.value.toFixed(2) +
      '\nRate: ' + this.f.rate.value.toString() + '%' +
      '\nStart Date: ' + this.f.startDate.value +
      '\nReset Date: ' + this.f.resetDate.value +
      '\nMaturity Date: ' + this.f.maturitydate.value);
    if (addBond) {
      this.loading = true;

      this.service.addBond({
        'upDate': new Date(this.f.update.value).toISOString(),
        'fund': 'OLD MUTUAL US DOLLAR MONEY MARKET FUND',//this.f.fund.value,
        'contract': this.f.contract.value,
        'currency': 'USD', //this.f.currency.value,
        'type': 'Commercial paper',//this.f.type.value,
        'security': this.f.security.value.toUpperCase(),
        'amount': this.f.amount.value,
        'rate': this.f.rate.value.toString() + '%',
        'startDate': new Date(this.f.startDate.value).toISOString(),
        'resetDate': new Date(this.f.resetDate.value).toISOString(),
        'maturityDate': new Date(this.f.maturitydate.value).toISOString(),
        'fundType': this.CurrentTab,
      })
        .pipe().subscribe(reply => {
          this.refreshAccData();
          this.BondForm.reset();
          this.securityvalue = undefined
          this.submitted = false
          Object.keys(this.BondForm.controls).forEach(key => {
            // this.BondForm.controls[key].setErrors(null);
            this.BondForm.controls[key].markAsPristine();
            this.BondForm.controls[key].markAsUntouched();
          });
          console.log('Added Bond Successfully');
          window.alert(reply.message);
          this.loading = false;
        }, error => {
          console.log(error);
          window.alert('Failed to add Bond: ' + error);
          this.loading = false;
        });
    }
  }

  filter(event: any) {
    this.contract = event.target.value.toString();
    this.refreshAccData();
  }

}
