import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';
import { ApiService } from 'src/app/api.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../../node_modules/bulma/css/bulma.css', 'header.component.css']
})
export class HeaderComponent implements OnInit {

  currentUser: User;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private service: ApiService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
  trigger() {
    const date = { "update": formatDate(new Date(), 'yyyy-MM-dd', 'en') };
    this.service.NAVbutton(date).pipe().subscribe(result => {
      window.location.reload();
      return result;
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  ngOnInit(): void {
  }
  public myFunction(event: any) {
    console.log(event);
    //x.getElementById("list").classList.toggle("change");
  }

}
